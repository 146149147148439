(function () {
    'use strict';

    angular.module('app.forms').component('formDomainDetail', {
        templateUrl: 'app/forms/domain/steps/detail/detail.html',
        controller: DomainDetailController,
        controllerAs: 'vm',
        bindings: {
            domain: '=',
            noLanguageSelector: '<',
        },
    });

    DomainDetailController.$inject = [
        'ng2SpellingToolRepoService',
        'ActiveRegionService',
        'MON_CMS',
        'coreDomainService',
        'gettextCatalog',
        'MON_CMS_OPTIONS',
        'ng2DefaultConstraintsAndExcludesService',
    ];
    /* @ngInject */
    function DomainDetailController (
        ng2SpellingToolRepoService,
        ActiveRegionService,
        MON_CMS,
        coreDomainService,
        gettextCatalog,
        MON_CMS_OPTIONS,
        ng2DefaultConstraintsAndExcludesService,
    ) {
        const vm = this;
        let initialCms;

        vm.$onInit = activate;
        vm.showCmsUrl = showCmsUrl;
        vm.showSpecialKey = showSpecialKey;
        vm.showPrefixPath = showPrefixPath;
        vm.showEPIServerPath = showEPIServerPath;
        vm.showContentIDTag = showContentIDTag;
        vm.showLanguageIDTag = showLanguageIDTag;
        vm.showTemplatePath = showTemplatePath;
        vm.getTemplatePlaceholder = getTemplatePlaceholder;
        vm.getUrl = getUrl;
        vm.showOptionalCmsUrl = showOptionalCmsUrl;
        vm.showSubdomain = showSubdomain;
        vm.isSubdomainRequired = isSubdomainRequired;
        vm.isSquizCms = isSquizCms;
        vm.isJoomlaCms = isJoomlaCms;
        vm.isKonform = isKonform;
        vm.isPloneCms = isPloneCms;
        vm.isAemCms = isAemCms;
        vm.showCmsPath = showCmsPath;
        vm.onCmsChange = onCmsChange;

        function activate () {
            if (!vm.domain.language) {
                setLanguage();
            }
            initialCms = vm.domain.cms;
            vm.isCmsTemplate = Boolean(vm.domain.cms === 'cms_template');
            vm.customers = [];
            vm.languages = [];
            vm.cmses = MON_CMS;

            vm.loadingLanguages = true;
            ng2SpellingToolRepoService.get().then(function (data) {
                vm.languages = data;
                vm.loadingLanguages = false;
            }, angular.noop);
            setupURL();
            setupSquizCmsOptions();
            setupJoomlaCmsOptions();
            setupPloneCmsOptions();
            setupCmsTemplateOption();
        }

        function setupCmsTemplateOption () {
            const cmsTemplateObj = {
                name: 'CMS template',
                id: 'cms_template',
            };

            if (vm.domain.cms === 'cms_template') {
                if (vm.cmses.indexOf(cmsTemplateObj) === -1) {
                    vm.cmses.push(cmsTemplateObj);
                    const cmsesWithTemplateOption = new Set(vm.cmses.map(cmsOption => JSON.stringify(cmsOption)));

                    return vm.cmses = Array.from(cmsesWithTemplateOption).map(cmsOption => JSON.parse(cmsOption));
                }
            } else {
                if (vm.cmses.some(option => option.id === 'cms_template')) {
                    const cleanUpCmses = new Set(vm.cmses.map(cmsOption => JSON.stringify(cmsOption)));
                    vm.cmses = Array.from(cleanUpCmses).map(cmsOption => JSON.parse(cmsOption));
                    const cmsTemplateIndex = vm.cmses.findIndex(option => option.id === cmsTemplateObj.id);
                    vm.cmses.splice(cmsTemplateIndex, 1);

                    return vm.cmses;
                }
            }
        }

        function setLanguage () {
            if (ActiveRegionService.isInRegion('AU')) {
                vm.domain.language = 'en_AU';
            } else {
                vm.domain.language = 'en_US';
            }
        }

        function showCmsUrl () {
            return coreDomainService.showCmsUrl(vm.domain);
        }

        function showOptionalCmsUrl () {
            return coreDomainService.showOptionalCmsUrl(vm.domain);
        }

        function showSpecialKey () {
            return coreDomainService.showSpecialKey(vm.domain);
        }

        function showEPIServerPath () {
            return ['episerver'].indexOf(vm.domain.cms) !== -1;
        }

        function showCmsPath () {
            return ['craft'].indexOf(vm.domain.cms) !== -1;
        }

        function showContentIDTag () {
            return ['episerver'].indexOf(vm.domain.cms) !== -1;
        }

        function showLanguageIDTag () {
            return ['episerver'].indexOf(vm.domain.cms) !== -1;
        }

        function showPrefixPath () {
            return coreDomainService.showPrefixPath(vm.domain);
        }

        function isSubdomainRequired () {
            return ['shopify', 'adobe', 'simsite'].indexOf(vm.domain.cms) !== -1;
        }

        function showSubdomain () {
            return ['shopify', 'opencities', 'adobe', 'simsite'].indexOf(vm.domain.cms) !== -1;
        }

        function showTemplatePath () {
            return coreDomainService.showTemplatePath(vm.domain);
        }

        function getTemplatePlaceholder () {
            return coreDomainService.getTemplatePlaceholder(vm.domain);
        }

        function getUrl () {
            if (['custom', 'episerver'].indexOf(vm.domain.cms) !== -1) {
                return 'base_url';
            }
            if (isAemCms()) {
                return 'cms_url';
            }
            return 'url';
        }

        function setupURL () {
            if (['custom'].indexOf(vm.domain.cms) !== -1) {
                if (
                    vm.domain.cms_extra.base_url == null && // If property is null or undefined.
                    vm.domain.cms_extra.url
                ) {
                    vm.domain.cms_extra.base_url = vm.domain.cms_extra.url;
                    vm.domain.cms_extra.url = '';
                }
            }
        }

        function isSquizCms () {
            setupDefaultSquizCmsOptions();
            return vm.domain.cms === 'squiz';
        }

        function isJoomlaCms () {
            setupDefaultJoomlaCmsOptions();
            return vm.domain.cms === 'joomla';
        }

        function isKonform () {
            return vm.domain.cms === 'konform';
        }

        function setupSquizCmsOptions () {
            vm.squizCmsOptions = [
                {
                    value: MON_CMS_OPTIONS.ADMIN,
                    name: gettextCatalog.getString('Admin'),
                },
                {
                    value: MON_CMS_OPTIONS.EDITOR,
                    name: gettextCatalog.getString('Editor'),
                },
            ];
        }

        function setupJoomlaCmsOptions () {
            vm.JoomlaCmsOptions = [
                {
                    value: MON_CMS_OPTIONS.ORIGINAL,
                    name: gettextCatalog.getString('Original'),
                },
                {
                    value: MON_CMS_OPTIONS.ALTERNATIVE,
                    name: gettextCatalog.getString('Alternative'),
                },
            ];
        }

        function setupDefaultSquizCmsOptions () {
            if (vm.domain.cms === 'squiz') {
                if (vm.domain.cms_extra == null || vm.domain.cms_extra.url_postfix == null) {
                    vm.domain.cms_extra = {
                        url_postfix: MON_CMS_OPTIONS.EDITOR,
                    };
                }
            } else if (vm.domain.cms !== 'squiz' && vm.domain.cms_extra && vm.domain.cms_extra.url_postfix) {
                delete vm.domain.cms_extra.url_postfix;
            }
        }

        function setupDefaultJoomlaCmsOptions () {
            if (vm.domain.cms === 'joomla') {
                if (vm.domain.cms_extra == null || vm.domain.cms_extra.url_structure == null) {
                    vm.domain.cms_extra = {
                        url_structure: MON_CMS_OPTIONS.ORIGINAL,
                    };
                }
            } else if (vm.domain.cms !== 'joomla' && vm.domain?.cms_extra?.url_structure) {
                delete vm.domain.cms_extra.url_structure;
            }
        }

        function isPloneCms () {
            setupDefaultPloneCmsOptions();
            return vm.domain.cms === 'plone';
        }

        function setupPloneCmsOptions () {
            vm.ploneCmsOptions = [
                {
                    value: MON_CMS_OPTIONS.STANDARD,
                    name: gettextCatalog.getString('Standard'),
                },
                {
                    value: MON_CMS_OPTIONS.ALTERNATIVE,
                    name: gettextCatalog.getString('Alternative'),
                },
            ];
        }

        function setupDefaultPloneCmsOptions () {
            if (vm.domain.cms === 'plone') {
                if (vm.domain.cms_extra == null || vm.domain.cms_extra.version == null) {
                    vm.domain.cms_extra = {
                        version: MON_CMS_OPTIONS.STANDARD,
                    };
                }
            } else if (vm.domain.cms !== 'plone' && vm.domain.cms_extra && vm.domain.cms_extra.version) {
                delete vm.domain.cms_extra.version;
            }
        }

        function isAemCms () {
            return vm.domain.cms === 'aem';
        }

        function onCmsChange (cms) {
            // We should not trigger on initial set up
            if (cms !== initialCms || (!cms && !initialCms)) {
                initialCms = undefined;
                ng2DefaultConstraintsAndExcludesService.updateCurrentCms(cms);
            }
        }
    }
})();
