(function () {
    'use strict';

    angular.module('modules.spotlight').component('monSpotlightSearch', {
        controller: SearchController,
        controllerAs: 'vm',
        templateUrl: 'app/modules/spotlight/components/search/search.html',
        bindings: {
            closeSpotlight: '&',
        },
    });

    SearchController.$inject = [
        'ng2PageRepoService',
        'ng2MonEventsService',
        'coreDomainService',
        '$state',
        '$stateParams',
        'ng2TotalDomainsService',
        'Lodash',
    ];
    /* @ngInject */
    function SearchController (ng2PageRepoService, ng2MonEventsService, coreDomainService, $state, $stateParams, ng2TotalDomainsService, Lodash) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.selectPage = selectPage;
        vm.goToPageDetails = goToPageDetails;
        vm.onEscape = onEscape;

        function activate () {
            vm.search = '';
            vm.pages = [];
            vm.domains = [];
            vm.page = null;
            ng2TotalDomainsService.domainsEssential.subscribe(function (domains) {
                vm.domains = domains || [];
            });
        }

        function onEscape (e) {
            if (e.keyCode === 27) {
                vm.closeSpotlight();
            }
        }

        function getPage () {
            if (vm.search.length < 3) {
                vm.pages.length = 0;
                vm.page = null;
                return;
            }

            var params = {
                page: 1,
                page_size: 100,
                search: vm.search,
                sort_by: 'url',
                sort_dir: 'asc',
            };

            vm.progress = ng2PageRepoService.allExistingPages(params).then(function (data) {
                vm.pages = data;
                if (vm.pages.length > 0) {
                    selectPage(vm.pages[0]);
                } else {
                    selectPage(null);
                }
            }, angular.noop);
        }

        function selectPage (page) {
            vm.page = page;
        }

        function goToPageDetails (target) {
            if (!vm.page) {
                return;
            }

            target = target || null;
            var domain = Lodash.find(vm.domains, { id: vm.page.domain_id });
            var domainGroup = null;
            var data = {
                customerId: $stateParams.customerId,
                page_id: vm.page.id,
                domainId: vm.page.domain_id,
            };

            if (vm.page.domain_group_id > 0) {
                domainGroup = Lodash.find(domain.domainGroups, { id: vm.page.domain_group_id }) || null;
            }

            if (angular.isObject(target)) {
                if (angular.isDefined(target.component)) {
                    data.target = target.component;
                }

                if (angular.isDefined(target.subComponent)) {
                    data.sub_target = target.subComponent;
                }
            }

            goToPage(data, domainGroup);
        }

        function goToPage (params, domainGroup) {
            if (domainGroup) {
                coreDomainService.setDomainGroup(domainGroup);
            } else {
                coreDomainService.removeDomainGroup();
            }
            vm.loadPage = $state
                .go('base.customer.domain.dashboard', params, { reload: 'base.customer.domain', inherit: false })
                .then(function () {
                    vm.closeSpotlight();
                })
                .finally(function () {
                    ng2MonEventsService.run('changed-domain');
                });
        }
    }
})();
