import { NgModule } from '@angular/core';

import { ApiClient, apiClientFactory } from './api-client';

import { HTTP_INTERCEPTORS, HttpHandler } from '@angular/common/http';
import { ResponseInterceptor } from './interceptors/response-interceptor';

import { SearchInterceptor } from './interceptors/search-interceptor';
import { MonHttpClientModule } from '@monsido/http/http.module';
import { ErrorInterceptor } from '@monsido/modules/endpoints/api/interceptors/error-interceptor';

import { RollbarErrorInterceptor } from './interceptors/rollbar-error-interceptor';
import { ApiHookInterceptor } from './interceptors/api-hook-interceptor';
import { AuthInterceptor } from './interceptors/auth-interceptor/auth.interceptor';
import { AccountRepoService } from '@client/app/services/api/account-repo/account-repo.service';

@NgModule({
    imports: [MonHttpClientModule],
    exports: [],
    declarations: [],
    providers: [
        AccountRepoService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SearchInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ResponseInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RollbarErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiHookInterceptor,
            multi: true,
        },
        {
            provide: ApiClient,
            useFactory: apiClientFactory,
            deps: [HttpHandler],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
})
export class ApiModule {}
