import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { ApiService } from '../api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

type PolicyOperator = 'contains_words' | 'contains_sentence' | 'contains' | 'starts_with' | 'ends_with'
| 'eq' | 'regex' | 'conforms_with' | 'lt' | 'lte' | 'gt' | 'gte' | 'date_age_gt' | 'date_age_lt' | 'css_selector';
type PolicySubject = 'page_text' | 'page_title' | 'page_title_length' | 'page_url' | 'link_url' | 'file_size' | 'external_link_count'
| 'incoming_link_count' | 'header_text' | 'header_text_length' | 'meta_header' | 'meta_header_length' | 'image_size' | 'page_html'
| 'link_text' | 'link_text_length' | 'image_text' | 'image_text_length' | 'readability_level';
type PolicyMode = 'search' | 'violation' | 'required';
type PolicyPriority = 'low' | 'medium' | 'high';
type PolicyTag = 'qa' | 'accessibility' | 'seo';
type PolicyState = 'ready' | 'queued';
type PolicyTargetType = 'Domain' | 'DomainGroup';
type PolicyRuleOperator = 'and' | 'or';

type PagePolicyRepoPolicyType = {
    archived_at: string
    created_at: string
    created_by: {
        id: number,
        email: string,
        first_name: string,
        last_name: string
    },
    id: number,
    ignored: boolean,
    labels: {
        color: string,
        created_at: string,
        id: number,
        name: string,
        updated_at: string,
    }[],
    links_count: number,
    matches: {
        name: string,
        type: PolicySubject,
        matches: string,
    }[]
    matches_count: number,
    mode: PolicyMode,
    name: string,
    note: string,
    pages_count: number,
    priority: PolicyPriority
    rules: {
        case_sensitive: boolean
        css_filters: {
            exclude: string[]
            limit: string[]
        }
        inverse: boolean
        multiline: boolean
        name: string,
        operator: PolicyOperator,
        query: string,
        sub_entry:''
        type: PolicySubject
    }[]
    rules_operator: PolicyRuleOperator,
    scheduled: boolean,
    state: PolicyState,
    subject: PolicySubject;
    tags: PolicyTag[]
    target_all_domains: boolean,
    targets: {
        id: number,
        latest_run_at: string
        links_count: number,
        matches_count: number,
        pages_count: number,
        title: string
        type: PolicyTargetType,
    }[]
    template: string,
    updated_at: string,
}

@Injectable({
    providedIn: 'root',
})
export class PagePolicyRepoService extends BaseRepo {

    constructor (protected sessionService: SessionService,private apiService: ApiService) {
        super();
    }

    getAll (pageId: number, params): Promise<MonTableCollection<PagePolicyRepoPolicyType>> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/pages/' + pageId + '/policies/', params);
    }

    update (pageId: number, params: PagePolicyRepoPolicyType & BaseRepoRequestType): Promise<PagePolicyRepoPolicyType> {
        params = params || {};
        params = this.setGroupId(params);
        return this.apiService.patch(
            'domains/' + this.getDomainId(params.domain_id) + '/pages/' + pageId + '/policies/' + params.id,
            params,
        );
    }

    destroy (pageId: number, id: number): Promise<void> {
        return this.apiService.delete('domains/' + this.getDomainId() + '/pages/' + pageId + '/policies/' + id);
    }
}
