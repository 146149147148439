import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.global.policy').component('globalPolicyPolicies', {
        templateUrl: 'app/pages/global/policy/policies/policies.html',
        controller: GlobalPolicyDashboardController,
        controllerAs: 'vm',
    });

    GlobalPolicyDashboardController.$inject = [
        'ng2LabelRepo',
        'PolicyRepo',
        '$controller',
        '$timeout',
        'activeFeatures',
        'PolicyHelper',
        'ng2MonEventsService',
        'gettextCatalog',
        '$stateParams',
        'policyService',
        'ng2MonModalService',
        'PolicyTableService',
        'FORM_CONSTANT',
    ];
    /* @ngInject */
    function GlobalPolicyDashboardController (
        ng2LabelRepo,
        PolicyRepo,
        $controller,
        $timeout,
        activeFeatures,
        PolicyHelper,
        ng2MonEventsService,
        gettextCatalog,
        $stateParams,
        policyService,
        ng2MonModalService,
        PolicyTableService,
        FORM_CONSTANT,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = timerDestroy;
        vm.getPage = getPage;
        vm.getPolicies = getPolicies;
        vm.deletePolicy = deletePolicy;
        vm.openPolicyForm = openPolicyForm;
        vm.runPolicy = runPolicy;
        vm.getPolicyTarget = getPolicyTarget;
        vm.getTooltipMessage = getTooltipMessage;
        vm.updatePolicy = updatePolicy;
        vm.getTooltipForTag = getTooltipForTag;
        vm.openTargets = openTargets;
        vm.onSearch = onSearch;
        vm.getLabels = getLabels;
        vm.onFiltersChanges = onFiltersChanges;
        vm.clonePolicy = clonePolicy;
        vm.archiveOrUnarchivePolicy = archiveOrUnarchivePolicy;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));
            vm.onStatus = onStatus;
            vm.matchCountPercentages = [];
            vm.maxCount = 0;
            vm.policies = [];
            vm.allLabels = [];
            vm.selectedCreators = [];
            vm.showLabels = activeFeatures.labels;
            vm.search = $stateParams.search || '';
            vm.currentSort = {
                by: 'matches_count',
                direction: 'desc',
            };
            vm.activeTab = null;
            vm.NoPolicies = false;
            vm.tabs = PolicyTableService.getTabs();
            vm.showArchivedPolicies = false;
            getPage();
            getLabels();
            getCreators();
        }

        function timerDestroy () {
            if (typeof vm.timerPromise === 'object') {
                $timeout.cancel(vm.timerPromise);
            }
        }

        function getPage () {
            vm.progress = getPolicies();
        }

        function getPolicies () {
            var params = {
                page_size: vm.pageSize,
                page: vm.page,
                search: vm.search || null,
                mode: PolicyTableService.getTab(),
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                archived: PolicyTableService.getShowArchivedPolicies(),
            };

            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }

            if (vm.selectedCreators && vm.selectedCreators.length > 0) {
                params['created_by[]'] = vm.selectedCreators;
            }

            timerDestroy();
            return PolicyRepo.getAll(params).then(function (policies) {
                vm.policies = policies;
                updateDropdowns();
                setMaxCount();
                var runningPolicies = vm.policies.filter(function (policy) {
                    return policy.state === 'running';
                }).length;
                vm.matchCountPercentages = [];
                if (runningPolicies > 0) {
                    startAutoReload();
                }
                if (params.mode === null && (params.search === null || params.search === '')) {
                    vm.NoPolicies = policies.length === 0;
                }
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const policy of vm.policies) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Run policy again'),
                        leftIcon: 'faPlay',
                        action: ()=> policy.archived_at === null && runPolicy(policy),
                        eventParams: { action: 'policy-run', params: { name: policy.name } },
                        disabled: policy.archived_at !== null,
                        shouldShow: policy.state !== 'running' && policy.state !== 'queued',
                    },
                    {
                        label: gettextCatalog.getString('Policy in queue'),
                        leftIcon: 'faPlay',
                        disabled: true,
                        shouldShow: policy.state === 'queued',
                    },
                    {
                        label: gettextCatalog.getString('Edit'),
                        leftIcon: 'faEdit',
                        action: ()=>policy.archived_at === null && vm.openPolicyForm(policy),
                        eventParams: { action: 'before-policy-edit', params: { name: policy.name } },
                        disabled: policy.archived_at !== null,
                    },
                    {
                        label: gettextCatalog.getString('Clone'),
                        leftIcon: 'faClone',
                        action: ()=>policy.archived_at === null && vm.clonePolicy(policy),
                        eventParams: { action: 'before-policy-clone', params: { name: policy.name } },
                        disabled: policy.archived_at !== null,
                    },
                    {
                        label: gettextCatalog.getString('Archive'),
                        leftIcon: 'faArchive',
                        action: ()=>archiveOrUnarchivePolicy(policy),
                        eventParams: { action: 'policy-archive', params: { name: policy.name, archiveStatus: policy.archived } },
                        shouldShow: policy.archived_at === null,
                    },
                    {
                        label: gettextCatalog.getString('Unarchive'),
                        leftIcon: 'faArchive',
                        action: ()=>archiveOrUnarchivePolicy(policy),
                        eventParams: { action: 'policy-archive', params: { name: policy.name, archiveStatus: policy.archived } },
                        shouldShow: policy.archived_at !== null,
                    },
                    {
                        label: gettextCatalog.getString('Delete'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>deletePolicy(policy),
                        eventParams: { action: 'policy-delete', params: { name: policy.name } },
                    },
                ];

                policy.dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        disabled: item.disabled,
                        action: ()=>{
                            if (item.eventParams) {
                                ng2MonEventsService.run('actionTrigger', item.eventParams);
                            }

                            if (item.action) {
                                item.action();
                            }
                        },
                    };
                });
            }
        }

        function getLabels () {
            ng2LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function getCreators () {
            var params = {
                page_size: 0,
            };
            return PolicyRepo.getPolicyAuthors(params).then(function (authors) {
                var creators = authors.map(function (author) {
                    return {
                        name: author.first_name + ' ' + (author.last_name || ''),
                        value: author.id,
                    };
                });
                setupFilters(creators);
            }, angular.noop);
        }

        function setupFilters (creators) {
            vm.filters.creators = [];
            if (creators.length) {
                vm.availableFilters.creators = creators;
            } else {
                delete vm.availableFilters.creators;
            }
        }

        function setMaxCount () {
            // Set the highest matches count no.
            vm.maxCount = vm.policies.reduce(function (n, policy) {
                return Math.max(n, policy.matches_count);
            }, vm.maxCount);
        }

        function deletePolicy (policy) {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString('Are you sure you want to delete the policy <strong>{{policyName}}</strong>', {
                        policyName: policy.name,
                    }),
                )
                .then(function () {
                    vm.progress = policyService.destroy(policy.id).then(function () {
                        getPage();
                    }, angular.noop);
                }, angular.noop);
        }

        function updatePolicy (policy) {
            var cPolicy = {};
            cPolicy.id = policy.id;
            cPolicy.labels = policy.labels.map(function (label) {
                return label.id;
            });
            policyUpdate(cPolicy).then(angular.noop, angular.noop);
        }

        function archiveOrUnarchivePolicy (policy) {
            var message = 'Are you sure you want to archive the policy <strong>{{policyName}}</strong>? We will no longer look for matches to this policy. You can always unarchive the policy again later on.';
            if (policy.archived_at !== null) {
                message = 'Are you sure you want to remove the policy <strong>{{policyName}}</strong> from the archive? It will become active again for the next scan.';
            }
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString(
                        message,
                        {
                            policyName: policy.name,
                        },
                    ),
                )
                .then(function () {
                    var clone = {
                        id: policy.id,
                        archived: policy.archived_at === null,
                    };
                    vm.progress = policyUpdate(clone).then(function () {
                        getPage();
                    }, angular.noop);
                }, angular.noop);
        }

        function openPolicyForm (policy) {
            var params = {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY,
                size: 'fw',
                data: {
                    policy: policy,
                },
            };


            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPage });
        }

        function clonePolicy (policy) {
            var params = {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY,
                size: 'fw',
                data: {
                    policy: policy.clone(),
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPage });
        }

        function runPolicy (policy) {
            ng2MonModalService
                .confirm(
                    gettextCatalog.getString('Are you sure you want to run the policy <strong>{{policyName}}</strong> again?', {
                        policyName: policy.name,
                    }),
                )
                .then(function () {
                    policyService.run(policy.id).then(function () {
                        getPage();
                    }, angular.noop);
                }, angular.noop);
        }

        function getPolicyTarget (policy) {
            return PolicyHelper.getType(policy);
        }

        function getTooltipMessage (policy) {
            return PolicyHelper.getTooltipMessageForType(policy);
        }

        function startAutoReload () {
            vm.timerPromise = $timeout(function () {
                getPolicies();
            }, 30000);
        }

        function getTooltipForTag (tag) {
            return PolicyHelper.getTag(tag);
        }

        function openTargets (policy) {
            var params = {
                body: 'globalPolicyDialogsTargets',
                size: 'md',
                data: { policy: policy },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function onSearch (search) {
            vm.page = 1;
            vm.search = search;
            getPage();
        }

        function onFiltersChanges (changes) {
            if (!changes) {
                return;
            }
            if (changes.changes) {
                changes = changes.changes;
            }
            if (changes.creators) {
                vm.selectedCreators = changes.creators.map(function (creator) {
                    return creator.value;
                });
            }
            vm.updateFilters(changes);
            vm.getPage();
        }

        function onStatus (tab) {
            vm.page = 1;
            vm.activeTab = tab;
            PolicyTableService.setTab(tab);
            getPage();
        }

        // PROTECTED

        function policyUpdate (policy) {
            return policyService.update(policy);
        }
    }
})();
