(function () {
    'use strict';

    angular.module('modules.export').component('csvExportsOverlay', {
        templateUrl: 'app/modules/export/components/overlay/csv-exports.html',
        controller: CsvExportsController,
        controllerAs: 'vm',
        bindings: {
            domainObservable: '<',
            monDialogId: '@?',
        },
    });

    CsvExportsController.$inject = [
        '$controller',
        'ng2MeRepoService',
        'ng2TotalDomainsService',
        '$state',
        'ng2MonEventsService',
        'monDialog',
        'coreDomainService',
        '$interval',
        'ng2MonUIRouterTransitionService',
    ];
    /* @ngInject */
    function CsvExportsController (
        $controller,
        ng2MeRepoService,
        ng2TotalDomainsService,
        $state,
        monEventsService,
        monDialog,
        coreDomainService,
        $interval,
        ng2MonUIRouterTransitionService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getDomainObjFromId = getDomainObjFromId;
        vm.goToDomain = goToDomain;
        vm.$onDestroy = onDestroy;
        vm.getTitle = getTitle;
        vm.getPage = getPage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.domains = [];
            vm.subscribedDomains = ng2TotalDomainsService.domainsEssential.subscribe(function (domains) {
                vm.domains = [];
                angular.copy(domains).forEach(function (domain) {
                    domain.type = 'Domain';
                    domain.domainGroups.forEach(function (domainGroup) {
                        domainGroup.domainId = domain.id;
                        domainGroup.domainTitle = domain.title;
                        domainGroup.url = domain.url;
                        domainGroup.type = 'DomainGroup';
                        vm.domains.push(domainGroup);
                    });
                    vm.domains.push(domain);
                });
            });
            getPage();
            initialRefresh();
        }

        function initialRefresh () {
            vm.initialRefreshTimer = $interval(function () {
                getPage();
                continousRefresh();
                $interval.cancel(vm.initialRefreshTimer);
            }, 10000);
        }

        function continousRefresh () {
            vm.continousRefreshTimer = $interval(function () {
                getPage();
            }, 30000);
        }

        function onDestroy () {
            vm.subscribedDomains.unsubscribe();
            if (vm.continousRefreshTimer) {
                $interval.cancel(vm.continousRefreshTimer);
            }
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
            };
            vm.promise = ng2MeRepoService.getExports(params).then(function (data) {
                var seoPrefix = 'seo_';
                for (var i = 0; i < data.length; i++) {
                    var exportData = data[i];
                    var originalCategoryRef = exportData.category_ref;
                    var prefix = originalCategoryRef && originalCategoryRef.substring(0, seoPrefix.length);
                    var categoryRef = 'N/A';
                    if (prefix === seoPrefix) {
                        categoryRef = 'SEO ' + originalCategoryRef.substring(seoPrefix.length).replace(/_/g, ' ');
                    }
                    exportData.category_ref = categoryRef;
                }
                vm.pages = data;
            }, angular.noop);
        }

        function getDomainObjFromId (id, type) {
            return (
                vm.domains.find(function (domain) {
                    return type === domain.type && domain.id === id;
                }) || {
                    url: '',
                    domainTitle: '',
                    title: '',
                }
            );
        }

        function getTitle (domain) {
            if (domain.type === 'DomainGroup') {
                return domain.domainTitle;
            } else if (domain.type === 'Domain') {
                return domain.title;
            } else {
                return 'Global';
            }
        }

        function goToDomain (domain) {
            if (!domain.id) {
                return;
            }

            var page = 'base.customer.domain.dashboard';
            var domainId = domain.id;

            if (domain.type === 'Domain') {
                coreDomainService.removeDomainGroup();
            } else {
                coreDomainService.setDomainGroup(domain);
                domainId = domain.domainId;
            }

            ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go(page, { domainId: domainId }).then(function () {
                    monEventsService.run('changed-domain');
                    monDialog.close(vm.monDialogId);
                }, angular.noop),
            );
        }
    }
})();
