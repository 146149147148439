(function () {
    'use strict';

    angular
        .module('modules.support.services.api')
        .factory('SupportAccessibilityChecksRepo', SupportAccessibilityChecksRepo);

    SupportAccessibilityChecksRepo.$inject = [
        'SupportApi',
        'SupportAccessibilityCheckModel',
        'ng2CDemoSupportCenterAccessibilityChecksRepoService',
    ];
    /* @ngInject */
    function SupportAccessibilityChecksRepo (
        Api,
        SupportAccessibilityCheckModel,
        ng2CDemoSupportCenterAccessibilityChecksRepoService,
    ) {
        return {
            getAll: getAll,
            get: get,
            getDecisionTree: getDecisionTree,
        };

        // /////////////

        /**
         * @memberOf SupportAccessibilityChecksRepo
         * @desc Get Help Center Accessibility Checks Collection
         * @param {Object} params - Query Parameters
         * @param {Object} config - $http provider's configuration options
         * @return {Promise<[]SupportAccessibilityCheckModel>}
         */
        function getAll (params, config) {
            params = params || {};
            return Api.get('accessibility_checks', params, config).then(toModels);
        }

        /**
         * @memberOf SupportAccessibilityChecksRepo
         * @desc Get Help Center Accessibility Check
         * @param {Number} accessibility_check_id - Support Center ID, not the actual Check ID
         * @param {Object} params - Query Parameters
         * @param {Object} config - $http provider's configuration options
         * @return {Promise<SupportAccessibilityCheckModel>}
         */
        function get (accessibility_check_id, params, config) {
            params = params || {};
            return Api.get('accessibility_checks/' + accessibility_check_id, params, config).then(toModel);
        }

        /**
         * @memberOf SupportAccessibilityChecksRepo
         * @desc Get Help Center Accessibility Decision Tree Collection
         * @param {Object} check - Accessibility Check
         * @param {Object} params - Query Parameters
         * @param {Object} config - $http provider's configuration options
         */
        // NB! Consider a different name for this method
        // eslint-disable-next-line no-unused-vars
        function getDecisionTree (check, params, config) {
            params = params || {};
            return ng2CDemoSupportCenterAccessibilityChecksRepoService.getDecisionTree(check);
            // TODO: Add Actual endpoint when the Backend is ready. Refer to Zube ID #18388
            // return Api.get("accessibility_checks/" + accessibility_check_id, params, config).then(toModel);
        }

        // PROTECTED

        function toModel (model) {
            return new SupportAccessibilityCheckModel(model);
        }

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }
    }
})();
