import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MonLogService } from '@monsido/ng2/services/mon-log-service/mon-log.service';
import { CommonModule } from '@angular/common';

import * as marked from 'marked';
import DOMPurify from 'dompurify';

@Component({
    selector: 'mon-markdown-viewer',
    standalone: true,
    templateUrl: './markdown-viewer.component.html',
    imports: [CommonModule],
})
export class MarkdownViewerComponent implements OnChanges {
    @Input() monMarkdown = '';
    sanitizedDOMString = '';

    constructor (
        private monLogService: MonLogService,
    ) {}

    ngOnChanges (changes: SimpleChanges): void {
        if (changes.monMarkdown) {
            const domString = this.markdownToHTML(this.monMarkdown);
            this.sanitizedDOMString = this.sanitizeDOMString(domString);
        }
    }

    private markdownToHTML (markdownString: string): string {
        try {
            const renderer = new marked.Renderer();
            const linkRenderer = renderer.link;
            renderer.link = (href, title, text): string => {
                const html = linkRenderer.call(renderer, href, title, text);
                return html.replace(/^<a /, '<a target="_blank" rel="nofollow noopener" ');
            };
            marked.setOptions({ renderer });
            return marked.parse(markdownString);
        } catch (e) {
            this.monLogService.warn(
                `Unable to convert markdown to HTML string:\n
                 Error: ${e}\n
                 ${markdownString}`,
            );
            return '';
        }
    }

    private sanitizeDOMString (domString: string): string {
        if (!domString) {
            return '';
        }

        try {
            return DOMPurify.sanitize(domString, {
                USE_PROFILES: { html: true },
                ADD_ATTR: ['target'],
            });
        } catch (e) {
            this.monLogService.warn(
                `Unable to sanitize DOM string\n
                 Error: ${e}\n
                 ${domString}\n
                `,
            );
            return '';
        }
    }
}
