import { HttpHandler } from '@angular/common/http';
import { MonHttpClientService } from '@monsido/http/http-client.service';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class ApiClient extends MonHttpClientService {
    abstract setHeader(name: string, value: string): void;
    abstract removeHeader(name: string): void;
    abstract setUrlPrefix(prefix: string): void;
}

export function apiClientFactory (handler: HttpHandler): MonHttpClientService {
    return new MonHttpClientService(handler);
}
