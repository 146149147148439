import { contextTokens } from '@monsido/ng2/services/request-auxiliary/request-auxiliary.service';

(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').component('spellingPagesDialog', {
        templateUrl: 'app/pages/qa/spellcheck/pages/index.html',
        controller: SpellingPagesIndex,
        controllerAs: 'vm',
        bindings: {
            word: '<',
            pageDetailTarget: '<',
        },
    });

    SpellingPagesIndex.$inject = ['$controller', 'ng2LabelRepo', 'SpellingRepo', '$filter', 'ng2CheckForDuplicatesService'];
    /* @ngInject */
    function SpellingPagesIndex ($controller, ng2LabelRepo, SpellingRepo, $filter, ng2CheckForDuplicatesService) {
        var vm = this;

        vm.getPage = getPage;
        vm.onFiltersChanged = onFiltersChanged;
        vm.onSearchChanged = onSearchChanged;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.filterShow = false;
            vm.search = null;
            vm.tabs = [];
            vm.pages = [];
            vm.progress = [];

            vm.selectedLabels = [];
            vm.selectedLanguages = [];

            vm.currentSort = { by: 'priority_score', direction: 'desc' };

            vm.filters = { labels: [], languages: [] };
            vm.availableFilters = {
                labels: [],
                languages: [],
            };
            vm.isFiltersOpen = false;
            getLabels();
            getLanguages();
            getPage();
            vm.exports = [
                {
                    data: {
                        category: 'pages_with_specific_potential_misspelling',
                        category_ref: vm.word.word,
                    },
                },
            ];
        }

        function getPage () {
            var params = {
                page: vm.page,
                search: vm.search,
                page_size: vm.pageSize,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }

            if (vm.selectedLanguages && vm.selectedLanguages.length > 0) {
                params['languages[]'] = vm.selectedLanguages;
            }

            vm.progress = SpellingRepo.getAllPagesWithError(vm.word.id, params, contextTokens.NO_GLOBAL).then(
                function (data) {
                    ng2CheckForDuplicatesService.checkForDuplicatePages(data);
                    vm.pages = data;
                },
                function () {
                    vm.pages = [];
                    vm.pages.total = 0;
                },
            );
        }

        function getLabels () {
            ng2LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
                vm.availableFilters.labels = data;
            }, angular.noop);
        }

        function getLanguages () {
            SpellingRepo.getAllLanguages().then(function (data) {
                vm.availableFilters.languages = data.map(function (language) {
                    return { name: $filter('translateLanguage')(language.lang), lang: language.lang };
                });
            }, angular.noop);
        }

        function onSearchChanged (search) {
            vm.search = search;
            getPage();
        }

        function onFiltersChanged (changes) {
            if (changes.labels) {
                vm.selectedLabels = changes.labels.map(function (label) {
                    return label.id;
                });
            } else {
                vm.selectedLabels = [];
            }

            if (changes.languages) {
                vm.selectedLanguages = changes.languages.map(function (language) {
                    return language.lang;
                });
            } else {
                vm.selectedLanguages = [];
            }

            getPage();
        }
    }
})();
