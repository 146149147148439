/*
    This service is a candidate for cleanup.
    It seems the code of this service is unreachable from any existing parts of the app.
*/

import { Injectable } from '@angular/core';
import { FormDomainScriptGuideService } from '@client/app/forms/domain-script-guide/form-domain-script-guide.service';
import { MonModalService } from '@client/app/modules/modals/services/mon-modal/mon-modal.service';
import { DomainService } from '@client/app/services/entities/domain/domain.service';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { Scripts } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';

@Injectable({
    providedIn: 'root',
})
export class ng2AdminDomainsService {

    constructor (
        private formDomainScriptGuideService: FormDomainScriptGuideService,
        private monModalService: MonModalService,
        private translateService: TranslateService,
        private domainService: DomainService,
    ) { }

    updateDomainSettings (domain: Domain): void {
        if (this.formDomainScriptGuideService.isScriptBlockEdited()) {
            const settings = this.formDomainScriptGuideService.getDomainSettings();

            this.monModalService
                .confirm({ message: this.translateService.getString('Do you want to update the Script Settings, for this Domain?') })
                .then(() => {
                    this.domainService.update(domain.id, {
                        settings: {
                            scripts: settings as Scripts,
                        },
                    });
                }, () => {});
        }
    }
}
