import { Injectable } from '@angular/core';
import { CrawlType, AccessibilityVersionChangeType, ChangeTypeEnum, AccessibilityGuidelineVersion } from './accessibility-guideline-version.interface';
import { AccessibilityGuidelinesInfoService } from 'app/modules/accessibility/services/mon-accessibility-guidelines/accessibility-guideline-info.service';
import { AccessibilityGuidelineInterface } from 'app/modules/accessibility/interfaces/acc-info-interface';
import { TranslateService } from '@client/app/services/translate/translate.service';
import { GuidelineInfoService } from './guideline-info/guideline-info.service';

const updateWcagLinks = {
    4: 'https://help.monsido.com/en/articles/8985990-accessibility-checks-update-april-2024',
    5: 'https://help.monsido.com/en/articles/9450664',
};
const latestGuidelineVersion = 5;

/** Service that computes the accessibility guideline changes between crawl history entries
 */
@Injectable()
export class AccessibilityGuidelineVersionService {
    versionUpdateText: string = '';
    a11yGuidelineLink = 'https://help.monsido.com/en/articles/5951206-visual-indication-when-you-have-switched-accessibility-guideline';
    emptyCrawlsArray: AccessibilityVersionChangeType[] = [];

    private accessibilityGuidelineVersions: Record<string, AccessibilityGuidelineVersion>;

    constructor (
        private accessibilityGuideLinesInfoService: AccessibilityGuidelinesInfoService,
        private translateService: TranslateService,
        private guidelineInfoService: GuidelineInfoService,
    ) {
        this.versionUpdateText = this.translateService.getString('Accessibility checks update');
        this.accessibilityGuidelineVersions = {
            ...this.guidelineInfoService.qw,
            ...this.guidelineInfoService.wcag,
        };
    }

    /** Get accessibility guidelines list then computes and
     * returns a Promise of an array of accessibility version change objects from crawls
     * @param crawlsArray - Sorted array of crawl history entries
     * */
    public async getVersions (crawlsArray: CrawlType[]): Promise<AccessibilityVersionChangeType[]> {
        if (crawlsArray.length <= 1) {
            return this.emptyCrawlsArray;
        }

        const abbrs = crawlsArray.map((crawl) => crawl.accessibility_guideline);

        const guidelines = await this.accessibilityGuideLinesInfoService.get(abbrs);
        return this.getVersionsFromGuidelines(crawlsArray, guidelines);
    }

    /** Returns an array of accessibility version change objects from crawls
     * @param crawls - Sorted array of crawl history entries
     * @param {AccessibilityGuidelineInterface[]} guidelines - Accessibility guidelines array
     * */
    private getVersionsFromGuidelines (
        crawls: CrawlType[],
        guidelines: AccessibilityGuidelineInterface[],
    ): AccessibilityVersionChangeType[] {
        const result: AccessibilityVersionChangeType[] = [];

        if (crawls.length <= 1 || guidelines.length === 0) {
            return this.emptyCrawlsArray;
        }

        for (let i = 1; i < crawls.length; i++) {
            const curCrawl = crawls[i];
            const prevCrawl = crawls[i - 1];

            if (curCrawl.accessibility_guideline !== prevCrawl.accessibility_guideline) {
                const accessibilityGuideline = (curCrawl.accessibility_guideline || '').split('_v')[0];
                const version = this.accessibilityGuidelineVersions[accessibilityGuideline];

                if (version) {
                    const { prevGuideline, currentGuideline } = this.getChangedGuidelines([prevCrawl, curCrawl], guidelines);
                    const change = this.getChangeType(currentGuideline, prevGuideline);
                    const newVersionNumber = parseInt((currentGuideline?.abbr || '').split('_v')[1]);

                    const res: AccessibilityVersionChangeType = {
                        title: '',
                        infoLink: '',
                        time: curCrawl.post_processing_done_at,
                        changeType: change,
                    };
                    const currentIsQw = currentGuideline && !!this.guidelineInfoService.qw[currentGuideline.abbr];

                    switch (change) {
                        case ChangeTypeEnum.BY_USER:
                            res.title = version.title;
                            res.infoLink = this.a11yGuidelineLink;
                            break;
                        case ChangeTypeEnum.NEW_VERSION:
                            res.title = this.versionUpdateText;
                            res.infoLink = currentIsQw ? this.a11yGuidelineLink : (updateWcagLinks[newVersionNumber] || this.a11yGuidelineLink);
                            break;
                        case ChangeTypeEnum.NEW_VERSION_AND_BY_USER:
                            res.title = `${version.title} - ${this.versionUpdateText}`;
                            res.infoLink = currentIsQw ? this.a11yGuidelineLink : (updateWcagLinks[newVersionNumber] || this.a11yGuidelineLink);
                            break;
                        default: break;
                    }

                    result.push(res);

                }
            }
        }
        return result;
    }

    private getChangedGuidelines (crawlsList: [CrawlType, CrawlType], guidelines: AccessibilityGuidelineInterface[]): {
        prevGuideline?: AccessibilityGuidelineInterface,
        currentGuideline?: AccessibilityGuidelineInterface,
    } {
        const [prev, current] = crawlsList;

        let currentGuideline: AccessibilityGuidelineInterface | undefined;
        let prevGuideline: AccessibilityGuidelineInterface | undefined;

        for (const guideline of guidelines) {
            if (guideline.abbr === current.accessibility_guideline) {
                currentGuideline = guideline;
            }
            if (guideline.abbr === prev.accessibility_guideline) {
                prevGuideline = guideline;
            }
            if (prevGuideline && currentGuideline) {
                break;
            }
        }

        return {
            prevGuideline,
            currentGuideline,
        };
    }

    private getChangeType (currentGuideline?: AccessibilityGuidelineInterface, prevGuideline?: AccessibilityGuidelineInterface): ChangeTypeEnum {
        if (currentGuideline && prevGuideline) {
            const isCurrentQw = !!this.guidelineInfoService.qw[currentGuideline.abbr];
            const isCurrentWcag = !!this.guidelineInfoService.wcag[currentGuideline.abbr];
            const isPrevQw = !!this.guidelineInfoService.qw[prevGuideline.abbr];
            const isPrewWcag = !!this.guidelineInfoService.wcag[prevGuideline.abbr];


            if ((isCurrentQw && !isPrevQw) || (isCurrentWcag && !isPrewWcag)) {
                return ChangeTypeEnum.BY_USER;
            }

            const [areBothNewVersion, areBothSameVersion] = this.areBothNewAndSameVersion(currentGuideline, prevGuideline);

            if (currentGuideline.previous_guideline_id === prevGuideline.id) {
                return ChangeTypeEnum.NEW_VERSION;
            }

            if (!areBothNewVersion &&
                !areBothSameVersion &&
                prevGuideline.previous_guideline_id !== currentGuideline.previous_guideline_id &&
                currentGuideline.previous_guideline_id !== prevGuideline.id) {
                return ChangeTypeEnum.NEW_VERSION_AND_BY_USER;
            }
        }

        return ChangeTypeEnum.BY_USER;
    }

    private areBothNewAndSameVersion (curr: AccessibilityGuidelineInterface, prev: AccessibilityGuidelineInterface): boolean[] {
        const target = `_v${latestGuidelineVersion}`;

        const currVersion = curr.abbr.slice(-3);
        const prevVersion = prev.abbr.slice(-3);

        return [currVersion.includes(target) && prevVersion.includes(target), curr.abbr === prev.abbr];
    }
}
