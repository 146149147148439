import { Injectable } from '@angular/core';
import { CommentsEntity } from '@client/app/modules/accessibility/interfaces/issue-interface';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { ApiService } from '../api-service/api.service';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

type responsibility = 'Visual Design' | 'UX Design' | 'Content Authoring' | 'Front-end Delevopment';

type PageAccessibilityRepoCheckErrorType = {
    check_id: number,
    comments: CommentsEntity[],
    created_at: string,
    extra: unknown,
    id: number
    ignored: boolean,
    isBodyReview: boolean,
    sequence_no: number,
    source_code: string,
    source_code_id: number
    type: string,
    updated_at: string
}

type PageAccessibilityRepoCheckErrorsUpdateType = {
    id: number,
    ignored_reviewed: boolean,
    type: string,
}

type PageAccessibilityRepoCheckType = {
    comments: CommentsEntity[],
    description: string
    difficulty: number,
    errors?: MonTableCollection<PageAccessibilityRepoCheckErrorType>,
    errors_count?: number,
    friendly_name: string,
    id: number,
    ignored?: boolean,
    ignored_on_page?: boolean,
    impact: string,
    issue_abbr?: string,
    issue_number?: string,
    mobile: boolean,
    name: string,
    number: number | null,
    responsibilities: responsibility[],
    result_type: string,
    tags: string[],
}

type PageAccessibilityRepoCheckUpdateType = BaseRepoRequestType & {
    type: 'check',
    ignored: boolean,
};

@Injectable({
    providedIn: 'root',
})
export class PageAccessibilityRepoService extends BaseRepo {

    constructor (protected sessionService: SessionService, private apiService: ApiService) {
        super();
    }

    updateCheck (pageId: number, checkId: number, params: PageAccessibilityRepoCheckUpdateType): Promise<PageAccessibilityRepoCheckType> {
        params = params || {};
        const domainId = this.getDomainId(params.domain_id);
        return this.apiService.patch(
            `domains/${domainId}/pages/${pageId}/accessibility/checks/${checkId}`,
            params,
        );
    }

    getAllChecks (pageId: number, params: BaseRepoRequestType): Promise<PageAccessibilityRepoCheckType[]> {
        params = params || {};
        const domainId = this.getDomainId(params.domain_id);
        params = this.setGroupId(params);
        return this.apiService.get(
            `domains/${domainId}/pages/${pageId}/accessibility/checks/`,
            params,
        );
    }

    getAllCheckErrors (pageId: number, checkId: number, params: BaseRepoRequestType): Promise<MonTableCollection<PageAccessibilityRepoCheckErrorType>> {
        params = params || {};
        const domainId = this.getDomainId(params.domain_id);
        params = this.setGroupId(params);
        return this.apiService.get(
            `domains/${domainId}/pages/${pageId}/accessibility/checks/${checkId}/errors`,
            params,
        );
    }

    updateError (pageId: number, error: PageAccessibilityRepoCheckErrorType, params: BaseRepoRequestType): Promise<PageAccessibilityRepoCheckErrorType> {
        params = params || {};
        const domainId = this.getDomainId(params.domain_id);
        return this.apiService.patch(
            `domains/${domainId}/pages/${pageId}/accessibility/errors/${error.id}`,
            error,
        );
    }

    markIssueAsFixed (pageId: number, error: PageAccessibilityRepoCheckErrorType): Promise<void> {
        const domainId = this.getDomainId();
        return this.apiService.delete(
            `domains/${domainId}/pages/${pageId}/accessibility/errors/${error.id}`,
        );
    }

    markCheckAsFixed (pageId: number, checkId: number): Promise<void> {
        const domainId = this.getDomainId();
        return this.apiService.delete(
            `domains/${domainId}/pages/${pageId}/accessibility/checks/${checkId}/errors`,
        );
    }

    updateCheckErrors (pageId: number, payload: PageAccessibilityRepoCheckErrorsUpdateType): Promise<void> {
        const domainId = this.getDomainId();
        return this.apiService.patch(
            `domains/${domainId}/pages/${pageId}/accessibility/checks/${payload.id}/errors`,
            payload,
        );
    }
}
