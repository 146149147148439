(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityPdfTimeline', {
        templateUrl: 'app/pages/accessibility/pdf/pdf-timeline/pdf-timeline.html',
        controller: AccessibilityPdfTimelineController,
        controllerAs: 'vm',
        bindings: {
            type: '@',
        },
    });

    AccessibilityPdfTimelineController.$inject = ['$filter', 'gettextCatalog', 'ng2CrawlsRepoService', 'moment'];

    function AccessibilityPdfTimelineController ($filter, gettextCatalog, ng2CrawlsRepoService, moment) {
        var vm = this;

        vm.$onInit = activate;

        function activate () {
            setupChart();
            getPage();
        }

        function setupChart () {
            vm.chartLabels = [];
            vm.chartSeries = [
                gettextCatalog.getString('PDF files reviewed'),
                gettextCatalog.getString('PDF files pending'),
            ];
            vm.chartData = [[], []];
            vm.chartColors = ['#88bddf', 'rgba(255,217,127,1)'];
            vm.chartOptions = {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxWidth: 0,
                        padding: 0,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: 4,
                                maxTicksLimit: 5,
                                callback: function (value) {
                                    return $filter('monNumber')(value);
                                },
                                min: 0,
                            },
                            stacked: true,
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                    itemSort: function (a, b) {
                        return b.datasetIndex - a.datasetIndex;
                    },
                },
            };
            vm.chartOverrides = [
                {
                    lineTension: 0,
                    fill: true,
                    backgroundColor: 'rgba(136,189,223, 0.5)',
                    borderColor: '#88bddf',
                    pointBorderColor: '#88bddf',
                },
                {
                    lineTension: 0,
                    fill: true,
                    backgroundColor: 'rgba(255,217,127, 0.5)',
                    borderColor: 'rgba(255,217,127,1)',
                    pointBorderColor: 'rgba(255,217,127,1)',
                },
            ];
        }

        function getPage () {
            var params = {
                page_size: 6,
            };

            ng2CrawlsRepoService.getAll(params).then(function (data) {
                data = sortChartData(data);
                vm.chartData[0] = data.map(function (crawl) {
                    if (!crawl.accessibility_reviewed_documents) {
                        return 0;
                    }
                    return crawl.accessibility_reviewed_documents.pdf[vm.type];
                });
                vm.chartData[1] = data.map(function (crawl) {
                    if (!crawl.file_counts || !crawl.file_counts.pdf) {
                        return 0;
                    }
                    if (crawl.accessibility_reviewed_documents) {
                        return crawl.file_counts.pdf[vm.type] - crawl.accessibility_reviewed_documents.pdf[vm.type];
                    }
                    return crawl.file_counts.pdf[vm.type];
                });
                for (var k = 0; k < data.length; k++) {
                    vm.chartLabels.push(moment(data[k].date).format('MMM DD'));
                }
            }, angular.noop);
        }

        function sortChartData (chartData) {
            return chartData.sort(function (a, b) {
                var dateA = new Date(a.queued_at);
                    var dateB = new Date(b.queued_at);
                if (dateA > dateB) {
                    return 1;
                }
                if (dateA < dateB) {
                    return -1;
                }
                return 0;
            });
        }
    }
})();
