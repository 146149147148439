import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').component('spellingErrorsIndex', {
        templateUrl: 'app/pages/qa/spellcheck/errors/index.html',
        controller: SpellingErrorsIndex,
        controllerAs: 'vm',
        bindings: {
            type: '@',
            headline: '@?',
            pageDetailTarget: '@',
        },
    });

    SpellingErrorsIndex.$inject = [
        'SpellingRepo',
        '$controller',
        'gettextCatalog',
        '$stateParams',
        'Dialog',
        '$filter',
        'monDialog',
        'ng2ActiveFeatureService',
        'spellService',
        'Lodash',
        'ng2LabelRepo',
        'ng2MonEventsService',
    ];
    /* @ngInject */
    function SpellingErrorsIndex (
        SpellingRepo,
        $controller,
        gettextCatalog,
        $stateParams,
        Dialog,
        $filter,
        monDialog,
        ng2ActiveFeatureService,
        spellService,
        Lodash,
        ng2LabelRepo,
        ng2MonEventsService,
    ) {
        var vm = this;

        vm.showPages = showPages;
        vm.confirmMisspelling = confirmMisspelling;
        vm.playMisspelling = playMisspelling;
        vm.getPage = getPage;
        vm.clearSelected = clearSelected;
        vm.getLabels = getLabels;
        vm.updateWordsLabel = updateWordsLabel;
        vm.updateFilters = updateFilters;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.selected = [];
            vm.type = vm.type || 'confirmed';
            vm.domainId = $stateParams.domainId;
            vm.search = null;
            vm.headline = vm.headline || gettextCatalog.getString('Misspellings');
            vm.words = [];
            vm.tabs = [{ name: gettextCatalog.getString('All'), faIcon: 'fas fa-language', value: null }];
            vm.activeTab = vm.tabs[0].value;
            vm.showPlay = false;
            vm.progress = null;
            vm.showAutofix = ng2ActiveFeatureService.isFeatureActive('page_fix_qa_misspelling') && vm.type !== 'potential';
            vm.currentSort = { by: 'html_pages_count', direction: 'desc' };
            vm.subheaderTranslation = '';
            vm.filters = { labels: [] };
            vm.availableFilters = {
                labels: [],
            };
            vm.isFiltersOpen = false;
            getAllLanguages();
            getPage();
            getLabels();
            vm.reloadMisspellingEventId = ng2MonEventsService.addListener('reloadMisspelling', function () {
                getPage();
            });
        }

        function getPage () {
            vm.selected = [];
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                language: vm.activeTab,
                search: vm.search,
                domainId: vm.domainId,
                classification: vm.type,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                page_fixed: false,
            };

            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }

            vm.promise = SpellingRepo.getAllErrors(params).then(function (data) {
                vm.words = data;
                vm.showPlay = data.length > 0;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function confirmMisspelling (word) {
            spellService.confirmWord(word.id).then(function () {
                getPage();
            }, angular.noop);
        }

        function showPages (word) {
            var params = {
                body: 'spellingPagesDialog',
                size: 'lg',
                data: {
                    word: word,
                    pageDetailTarget: vm.pageDetailTarget,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPage });
        }

        function playMisspelling () {
            Dialog.create('spellingPlay', 'sm-center fade-animation', {
                words: vm.words,
                language: vm.activeTab,
                search: vm.search,
                type: vm.type,
            }).closePromise.then(function () {
                getPage();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.words.total, '0,0'),
                'One word found',
                '{{$count}} words found',
                {},
            );
        }

        function clearSelected () {
            vm.selected = [];
        }

        function getAllLanguages () {
            SpellingRepo.getAllLanguages({ domainId: vm.domainId }).then(function (data) {
                Lodash.forEach(data, function (item) {
                    var languageCount = item.potential_misspellings_count;
                    if (vm.type === 'confirmed') {
                        languageCount = item.confirmed_misspellings_count;
                    }
                    vm.tabs.push({
                        name: gettextCatalog.getString('{{languageName}} ({{languageCount}})', {
                            languageName: $filter('translateLanguage')(item.lang),
                            languageCount: $filter('monNumber')(languageCount),
                        }),
                        faIcon: 'fas fa-language',
                        value: item.lang,
                    });
                });
            }, angular.noop);
        }

        function getLabels () {
            ng2LabelRepo.getLabels().then(function (data) {
                vm.labels = data || [];
                vm.availableFilters.labels = data || [];
            }, angular.noop);
        }

        function updateWordsLabel (word) {
            var params = {
                id: word.id,
                labels: word.labels.map(function (label) {
                    return label.id;
                }),
            };

            if (vm.headline === 'Potential Misspellings') {
                params.classification = 'potential';
            }

            SpellingRepo.confirm(params.id, params).then(angular.noop, angular.noop);
        }

        function updateFilters (changes) {
            if (!changes) {
                return;
            }
            vm.selectedLabels = changes[gettextCatalog.getString('labels')].map(function (label) {
                return label.id;
            });

            getPage();
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.reloadMisspellingEventId);
        }
    }
})();
