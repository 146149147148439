(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').component('spellingDashboard', {
        templateUrl: 'app/pages/qa/spellcheck/dashboard/dashboard.html',
        controller: SpellingDashboardController,
        controllerAs: 'vm',
    });

    SpellingDashboardController.$inject = [
        'SpellingRepo',
        '$stateParams',
        'ng2CrawlsRepoService',
        '$q',
        'QASpellcheckLineChartService',
        'gettextCatalog',
    ];
    /* @ngInject */
    function SpellingDashboardController (
        SpellingRepo,
        $stateParams,
        ng2CrawlsRepoService,
        $q,
        QASpellcheckLineChartService,
        gettextCatalog,
    ) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            var lineChartService = new QASpellcheckLineChartService();
            vm.loading = true;
            vm.series = lineChartService.getSeries();
            vm.colors = lineChartService.getColors();
            vm.options = lineChartService.getOption();
            vm.overrides = lineChartService.getOverride();

            var promises = [];
            vm.exports = [
                {
                    name: gettextCatalog.getString('Spellcheck Export Summary'),
                    data: {
                        category: 'spellcheck_summary',
                    },
                },
            ];
            promises.push(getSummary());
            promises.push(getHistory());
            promises.push(getLanguages());

            $q.all(promises)
                .then(function (data) {
                    vm.summary = data[0];
                    var plainData = data[1].reverse();
                    vm.labels = lineChartService.getLabels(plainData);
                    vm.data = lineChartService.getData(plainData);

                    vm.commonLanguage = getCommonLanguages(data[2]);
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getSummary () {
            return SpellingRepo.getSummary({ domainId: $stateParams.domainId });
        }

        function getLanguages () {
            return SpellingRepo.getAllLanguages({ domainId: $stateParams.domainId });
        }
        function getCommonLanguages (langArray) {
            // Sorts array so the entry with highest html_page_count & document_count are first
            var languages = angular.copy(langArray).sort(function (a, b) {
                return a.html_page_count > b.html_page_count ? -1 : b.html_page_count > a.html_page_count ? 1 : 0;
            });
            return languages[0].lang;
        }

        function getHistory () {
            var params = {
                page_size: 5,
            };

            return ng2CrawlsRepoService.getAll(params);
        }
    }
})();
