import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';
    angular.module('app.components.pages').component('projectsAndDomains', {
        templateUrl: 'app/pages/dashboard/projects-and-domains/projects-and-domains.html',
        controller: ProjectsAndDomainsController,
        controllerAs: 'vm',
        bindings: {
            progress: '<',
            domainObservable: '<',
            sort: '<',
            onPageChange: '&',
            onPerPageChange: '&',
            getPage: '&',
            onSort: '&',
            isFiltersOpen: '=?',
            onFiltersChanges: '&',
            filters: '<',
            availableFilters: '<',
        },
    });

    ProjectsAndDomainsController.$inject = [
        'ng2LabelRepo',
        '$state',
        'ng2MonEventsService',
        'ng2SessionService',
        'activeFeatures',
        'MON_MODULES',
        '$filter',
        'rx',
        'Lodash',
        'ng2DomainService',
        'coreDomainService',
        'ng2CoreLoginService',
        'maxDomainGroupVisible',
        'ng2ActiveFeatureService',
    ];

    function ProjectsAndDomainsController (
        ng2LabelRepo,
        $state,
        ng2MonEventsService,
        ng2SessionService,
        activeFeatures,
        MON_MODULES,
        $filter,
        rx,
        Lodash,
        ng2DomainService,
        coreDomainService,
        ng2CoreLoginService,
        maxDomainGroupVisible,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = destroy;
        vm.pageChange = pageChange;
        vm.perPageChange = perPageChange;
        vm.goTo = goTo;
        vm.domainAccCheckCount = domainAccCheckCount;
        vm.updateDomain = updateDomain;
        vm.openScanDetails = openScanDetails;
        vm.getLabels = getLabels;
        vm.onHover = onHover;
        vm.onHoverLeave = onHoverLeave;
        vm.toSection = toSection;
        vm.getUnavailableModuleCount = getUnavailableModuleCount;
        vm.getNoDataWidth = getNoDataWidth;
        vm.hasAccessToSEOModule = hasAccessToSEOModule;
        vm.hasAccessToQAModule = hasAccessToQAModule;
        vm.hasAccessToPoliciesModule = hasAccessToPoliciesModule;
        vm.hasAccessToAccessibilityModule = hasAccessToAccessibilityModule;
        vm.hasAccessToDataPrivacyModule = hasAccessToDataPrivacyModule;
        vm.hasDomainOrDomainGroupData = hasDomainOrDomainGroupData;

        function activate () {
            // angular.extend(vm, $controller('BaseApiController', {vm: vm}));
            vm.hoverDomain = null;
            vm.destroyed = false;
            ng2SessionService.domain = null;
            ng2MonEventsService.run('changed-domain');
            vm.showLabels = activeFeatures.labels;
            vm.sort = vm.sort || {
                by: 'favorite',
                direction: 'asc',
            };
            vm.modules = MON_MODULES;
            vm.switchDomain = false;
            vm.switchDomainGroup = false;
            vm.showLabel = [];
            vm.isFiltersOpen = vm.isFiltersOpen || false;
            ng2CoreLoginService.getUser().then(
                function (user) {
                    vm.user = user;
                },
                function () {
                    vm.user = ng2SessionService.me;
                    vm.colSpanSpace = 0;
                },
            );
            getLabels();
            subscribeDomains();
        }

        function destroy () {
            vm.destroyed = true;
        }

        function subscribeDomains () {
            vm.domainObservable.subscribe(domains => vm.domains = domains);
        }

        function getLabels () {
            ng2LabelRepo.getLabels().then(function (data) {
                vm.allLabels = data;
            }, angular.noop);
        }

        function pageChange (page) {
            vm.onPageChange({ page: page });
        }

        function perPageChange (size) {
            vm.onPerPageChange({ pageSize: size });
        }

        function toSection (event, type, domain, domainGroup) {
            if (event) {
                event.stopPropagation();
            }
            var state;
            var params = {
                domainId: domain.id,
            };

            switch (type) {
                case 'policy':
                    state = 'base.customer.domain.policy.dashboard';
                    break;
                case 'qa':
                    state = 'base.customer.domain.qa.summary';
                    break;
                case 'accessibility':
                    state = 'base.customer.domain.accessibility.summary';
                    break;
                case 'seo':
                    state = 'base.customer.domain.seo.summary';
                    break;
                case 'pages':
                    state = 'base.customer.domain.pages.index';
                    break;
                case 'documents':
                    state = 'base.customer.domain.inventory.content.documents';
                    break;
                case 'dataprivacy':
                    state = 'base.customer.domain.data_privacy.summary';
                    break;
                default:
                    state = 'base.customer.domain.dashboard';
            }

            vm.switchDomain = domain.id;
            if (domainGroup !== undefined) {
                vm.switchDomainGroup = domainGroup.id;
                params.domain_group = domainGroup.id;
                coreDomainService.setDomainGroup(domainGroup);
            } else {
                coreDomainService.removeDomainGroup();
            }

            $state
                .go(state, params)
                .then(function () {
                    ng2MonEventsService.run('changed-domain');
                }, angular.noop)
                .finally(function () {
                    vm.switchDomain = false;
                    vm.switchDomainGroup = false;
                });
        }

        function goTo (domain, domainGroup) {
            toSection(null, 'dashboard', domain, domainGroup);
        }

        function domainAccCheckCount (accChecks) {
            var check;
            var total = 0;
            for (check in accChecks) {
                if (['error', 'warning', 'review'].indexOf(check) !== -1) {
                    total += accChecks[check] || 0;
                }
            }
            return total;
        }

        function updateDomain (domain) {
            var params = {};
            params.labels = domain.labels.map(function (label) {
                return label.id;
            });

            ng2DomainService.update(domain.id, params);
        }

        function openScanDetails (domain, event) {
            var domainObservable = new rx.BehaviorSubject(domain);
            event.stopPropagation();
            vm.domainObservable.subscribe(function (domains) {
                var nextDomain = domains.find(function (listDomain) {
                    return listDomain.id === domain.id;
                });

                if (nextDomain) {
                    domainObservable.onNext(nextDomain);
                } else {
                    domainObservable.onNext(domain);
                }
            });
            var params = {
                body: 'scanDetailsDialog',
                size: 'sm',
                classes: 'fade-animation middle center',
                data: {
                    domainObservable: domainObservable,
                },
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function getUnavailableModuleCount (domain, domainGroup) {
            if (vm.hasDomainOrDomainGroupData(domain, domainGroup)) {
                return getNoDataTitleWidth();
            }
            return [
                hasAccessToSEOModule(domain, domainGroup),
                hasAccessToQAModule(domain, domainGroup),
                hasAccessToAccessibilityModule(domain, domainGroup),
                hasAccessToPoliciesModule(domain, domainGroup),
                hasAccessToDataPrivacyModule(domain, domainGroup),
            ].reduce(function (num, hasAccess) {
                if (!hasAccess) {
                    return num + 1;
                }
                return num;
            }, 1);
        }

        function getNoDataWidth () {
            // not using helper function because they always return false on no data
            return [
                $filter('hasAccess')(vm.user, MON_MODULES.seo),
                $filter('hasAccess')(vm.user, MON_MODULES.data_privacy),
                $filter('hasAccess')(vm.user, MON_MODULES.qa),
                $filter('hasAccess')(vm.user, MON_MODULES.policies),
                $filter('hasAccess')(vm.user, MON_MODULES.accessibility),
            ].reduce(function (num, hasAccess) {
                if (!hasAccess) {
                    return num - 1;
                }
                return num;
            }, 7);
        }

        function getNoDataTitleWidth () {
            return [
                $filter('hasAccess')(vm.user, MON_MODULES.seo),
                $filter('hasAccess')(vm.user, MON_MODULES.data_privacy),
                $filter('hasAccess')(vm.user, MON_MODULES.qa),
                $filter('hasAccess')(vm.user, MON_MODULES.policies),
                $filter('hasAccess')(vm.user, MON_MODULES.accessibility),
            ].reduce(function (num, hasAccess) {
                if (!hasAccess) {
                    return num + 1;
                }
                return num;
            }, 1);
        }

        function hasAccessToSEOModule (domain, domainGroup) {
            if (!ng2ActiveFeatureService.isFeatureActivePure('seo_all')) {
                return false;
            }
            if (vm.hasDomainOrDomainGroupData(domain, domainGroup)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.seo);
        }

        function hasAccessToDataPrivacyModule (domain, domainGroup) {
            if (vm.hasDomainOrDomainGroupData(domain, domainGroup)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.data_privacy);
        }

        function hasAccessToQAModule (domain, domainGroup) {
            if (!ng2ActiveFeatureService.isFeatureActivePure('qa_module')) {
                return false;
            }
            if (vm.hasDomainOrDomainGroupData(domain, domainGroup)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.qa);
        }

        function hasAccessToPoliciesModule (domain, domainGroup) {
            if (!ng2ActiveFeatureService.isFeatureActivePure('policies_all')) {
                return false;
            }
            if (vm.hasDomainOrDomainGroupData(domain, domainGroup)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.policies);
        }

        function hasAccessToAccessibilityModule (domain, domainGroup) {
            if (vm.hasDomainOrDomainGroupData(domain, domainGroup)) {
                return false;
            }
            return $filter('hasAccess')(vm.user, MON_MODULES.accessibility);
        }

        function hasDomainOrDomainGroupData (domain, domainGroup) {
            return (domain && domain.crawl_status !== null) || (domainGroup && !domainGroup.created_at);
        }

        function onHover (domain) {
            if (domain) {
                vm.showLabel[domain.id] = true;
                if ($filter('hasDomainAccess')(domain)) {
                    vm.hoverDomain = domain.id;
                }
            }
        }

        function onHoverLeave (domain) {
            if (domain) {
                vm.showLabel[domain.id] = false;
                vm.hoverDomain = false;
            }
        }
    }
})();
