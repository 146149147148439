import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.policy').component('pagesPolicyPolicies', {
        templateUrl: 'app/pages/policy/policies/policies.html',
        controller: PolicyController,
        controllerAs: 'vm',
    });

    PolicyController.$inject = [
        'PolicyRepo',
        'gettextCatalog',
        '$controller',
        '$timeout',
        'ng2SessionService',
        'PolicyHelper',
        'ng2MonEventsService',
        'ng2PageRepoService',
        'LinksRepo',
        '$q',
        'policyService',
        '$location',
        'Lodash',
        'PolicyTableService',
        '$filter',
        'FORM_CONSTANT',
        '$stateParams',
        'ParamService',
        'ng2MonModalService',
        '$state',
    ];

    /* @ngInject */
    function PolicyController (
        PolicyRepo,
        gettextCatalog,
        $controller,
        $timeout,
        ng2SessionService,
        PolicyHelper,
        ng2MonEventsService,
        ng2PageRepoService,
        LinksRepo,
        $q,
        policyService,
        $location,
        Lodash,
        PolicyTableService,
        $filter,
        FORM_CONSTANT,
        $stateParams,
        ParamService,
        ng2MonModalService,
        $state,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.getPolicyTarget = PolicyHelper.getType;
        vm.getTooltipMessage = PolicyHelper.getTooltipMessageForType;
        vm.hasRun = PolicyTableService.targetHasRun;
        vm.policyScanValid = PolicyTableService.policyScanValid;
        vm.runPolicy = runPolicy;
        vm.openPagesDialog = openPagesDialog;
        vm.openLinksDialog = openLinksDialog;
        vm.openPolicyForm = openPolicyForm;
        vm.getPage = getPage;
        vm.getDomainCrawlStatus = getDomainCrawlStatus;
        vm.onFiltersChanges = onFiltersChanges;
        vm.updatePolicy = updatePolicy;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BaseLabelsController', { vm: vm }));

            vm.openSelectedPolicy = null;
            vm.destroyed = false;
            vm.allPagesCount = 0;
            vm.documentCount = 0;
            vm.pagesCount = 0;
            vm.pdfCount = 0;
            vm.htmlCount = 0;
            vm.complianceLabels = ['errors', 'pages_count'];
            vm.policies = [];
            vm.tab = $stateParams.tab ? $stateParams.tab : null;
            vm.timerPromise = null;
            vm.policyScheduled = null;
            vm.currentSort = {
                by: 'matches_count',
                direction: 'desc',
            };
            vm.subheaderTranslation = '';

            vm.isAdmin = ng2SessionService.hasRole('customer_admin');
            var promises = [getPagesCount(), getPagesCount('pdf'), getPagesCount('html'), getPagesCount('document'), getLinksCount()];
            vm.setupProgress = $q.all(promises).then(function (values) {
                vm.allPagesCount = parseInt(values[0].total);
                vm.pdfCount = parseInt(values[1].total);
                vm.htmlCount = parseInt(values[2].total);
                vm.documentCount = parseInt(values[3].total);
                vm.linksCount = parseInt(values[4].total);
            }, angular.noop);
            vm.tabs = PolicyTableService.getTabs(false);
            setupCustomFilters();
            loadLocations();
            vm.onStatus(vm.tab); // Starts getPolicies
        }

        function onDestroy () {
            vm.destroyed = true;
            destroyTimer();
        }

        function loadLocations () {
            var params = $location.search();

            if (typeof params.search === 'string' && params.search.length > 0) {
                vm.search = params.search;
            }
            if (params.policy_id > 0) {
                vm.openSelectedPolicy = params.policy_id;
            }
        }

        function destroyTimer () {
            if (typeof vm.timerPromise !== null) {
                $timeout.cancel(vm.timerPromise);
                vm.timerPromise = null;
            }
        }

        function setupCustomFilters () {
            vm.availableFilters.scheduled = [
                {
                    name: gettextCatalog.getString('Scheduled'),
                    value: true,
                },
                {
                    name: gettextCatalog.getString('Not scheduled'),
                    value: false,
                },
            ];
            vm.filters.scheduled = [];
        }

        function getPagesCount (type) {
            var params = {
                page: 1,
                page_size: 1,
                type: type,
            };
            return ng2PageRepoService.getAll(params).then(
                function (res) {
                    return res;
                },
                function () {
                    var result = [];
                    result.total = 0;
                    return result;
                },
            );
        }

        function getLinksCount () {
            var params = {
                page: 1,
                page_size: 1,
                page_fixed: false,
            };
            return LinksRepo.getAll(params).then(
                function (res) {
                    return res;
                },
                function () {
                    var result = [];
                    result.total = 0;
                    return result;
                },
            );
        }

        function getPolicies () {
            var chain = $q.when();
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                mode: vm.activeTab,
                scheduled: vm.policyScheduled,
                search: vm.search || null,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
            };

            if (vm.openSelectedPolicy > 0) {
                params.page_size = 100;
            }

            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }

            return PolicyRepo.domainGetAll(params).then(function (policies) {
                policies.forEach(function (policy) {
                    chain = chain.then(function () {
                        if (vm.destroyed) {
                            return;
                        }
                        if (policy.pages_count === null || policy.links_count === null) {
                            if (policy.subject === 'link') {
                                return getPolicyLinkCount(policy);
                            } else {
                                return getPolicyPageCount(policy);
                            }
                        }
                    });
                });

                return chain.then(function () {
                    if (vm.destroyed) {
                        return;
                    }
                    if (vm.openSelectedPolicy > 0) {
                        var policy = Lodash.find(policies, { id: parseInt(vm.openSelectedPolicy) });
                        vm.openSelectedPolicy = null;
                        if (policy) {
                            vm.policies = [policy];
                            if (policy.subject !== 'link') {
                                openPagesDialog(policy);
                            } else {
                                openLinksDialog(policy);
                            }
                        }
                    } else {
                        vm.policies = policies;
                        if (PolicyTableService.areAnyPoliciesRunning(policies) && vm.timerPromise === null) {
                            startAutoReload();
                        }
                    }
                    updateDropdowns();
                    getSubHeaderDescription();
                });
            }, angular.noop);
        }

        function updateDropdowns () {
            for (const policy of vm.policies) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Run Policy'),
                        leftIcon: 'faPlay',
                        action: ()=>{
                            const message = gettextCatalog.getString('Are you sure you want to run the policy <strong>{{policyName}}</strong>?', { policyName: policy.name });
                            ng2MonModalService.confirm(message).then(()=>runPolicy(policy));
                        },
                        eventParams: { action: 'policy-run', params: { name: policy.name } },
                        disabled: policy.state === 'running' || getDomainCrawlStatus(),
                        shouldShow: policy.state !== 'queued',
                    },
                    {
                        label: gettextCatalog.getString('Policy in queue'),
                        leftIcon: 'faPlay',
                        shouldShow: policy.state === 'queued',
                    },
                    {
                        label: gettextCatalog.getString('Show on global policy list'),
                        leftIcon: 'faGavel',
                        action: ()=>$state.go('base.customer.global.policy.policies', { search: policy.name }),
                        eventParams: { action: 'go-to-global-policy-policy-list', from: 'domain-policy-policy-list', params: { name: policy.name } },
                        dataTestAttribute: 'policy-list',
                    },
                ];

                policy.dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        disabled: item.disabled,
                        action: ()=>{
                            if (item.eventParams) {
                                ng2MonEventsService.run('actionTrigger', item.eventParams);
                            }

                            item.action();
                        },
                        dataTestAttribute: item.dataTestAttribute,
                    };
                });
            }
        }

        function getPage () {
            ParamService.setParams({
                tab: vm.activeTab,
            });
            destroyTimer();
            vm.progress = getPolicies();
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.policies.total, '0,0'),
                '1 policy found',
                '{{$count}} policies found',
                {},
            );
        }

        function runPolicy (policy) {
            policyService
                .run(policy.id)
                .then(angular.noop, angular.noop)
                .finally(function () {
                    getPage();
                });
        }

        function openPagesDialog (policy) {
            var count = 0;
            switch (policy.subject) {
                case 'page':
                    count = vm.allPagesCount;
                    break;

                case 'page_html':
                case 'page_html_text':
                    count = vm.htmlCount;
                    break;

                case 'page_document':
                    count = vm.documentCount;
                    break;
            }
            destroyTimer();
            var params = {
                body: 'pagesPolicyDialogPages',
                size: 'lg',
                data: {
                    policy: policy,
                    pageCount: vm.htmlCount,
                    contentCount: count,
                    pdfCount: vm.pdfCount,
                    documentCount: vm.documentCount,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPage });
        }

        function openLinksDialog (policy) {
            destroyTimer();
            var params = {
                body: 'pagesPolicyDialogLinks',
                size: 'lg',
                data: { policy: policy },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPage });
        }

        function openPolicyForm () {
            var params = {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY,
                size: 'fw',
            };
            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPage });
        }

        function getDomainCrawlStatus () {
            return ng2SessionService.domain !== null && ng2SessionService.domain.crawl_status !== null;
        }

        function onFiltersChanges (changes) {
            vm.updateFilters(changes);
            updateCustomerFilters(changes);
            getPage();
        }

        function updatePolicy (policy) {
            var cPolicy = {};
            cPolicy.id = policy.id;
            cPolicy.labels = policy.labels.map(function (label) {
                return label.id;
            });
            policyService.update(cPolicy).then(angular.noop, angular.noop);
        }

        // PROTECTED

        function updateCustomerFilters (changes) {
            vm.policyScheduled = null;
            if (changes.changes) {
                changes = changes.changes;
            }

            if (changes.scheduled && changes.scheduled.length > 0) {
                var schedule = changes.scheduled[changes.scheduled.length - 1];
                vm.filters.scheduled = [schedule];
                vm.policyScheduled = schedule.value;
            }
        }

        function startAutoReload () {
            if (!vm.destroyed) {
                vm.timerPromise = $timeout(function () {
                    vm.timerPromise = null;
                    getPolicies();
                }, 30000);
            }
        }

        function getPolicyLinkCount (policy) {
            var params = {
                page: 1,
                page_size: 1,
            };
            return PolicyRepo.domainGetPolicyLinks(policy.id, params).then(
                function (policies) {
                    policy.links_count = parseInt(policies.total, 10);
                    policy.pages_count = 0;
                    policy.matches_count = policy.links_count;
                },
                function () {
                    policy.pages_count = 0;
                    policy.links_count = 0;
                    policy.matches_count = 0;
                },
            );
        }

        function getPolicyPageCount (policy) {
            var params = {
                page: 1,
                page_size: 1,
            };
            return PolicyRepo.domainGetPolicyPages(policy.id, params).then(
                function (policies) {
                    policy.pages_count = parseInt(policies.total, 10);
                    policy.links_count = 0;
                    policy.matches_count = policy.pages_count;
                },
                function () {
                    policy.pages_count = 0;
                    policy.links_count = 0;
                    policy.matches_count = 0;
                },
            );
        }
    }
})();
