(function () {
    'use strict';
    angular.module('modules.page_fix.services').factory('PageFixFormEditAltText', PageFixFormEditAltTextService);

    PageFixFormEditAltTextService.$inject = [];
    /* @ngInject*/
    function PageFixFormEditAltTextService () {
        var vm = this;
        vm.snippet = null;
        vm.match = null;
        return {
            setHTMLSnippet: setHTMLSnippet,
            canReadSource: canReadSource,
            getSource: getSource,
        };

        // ///////////////

        function setHTMLSnippet (snippet) {
            vm.snippet = snippet || '';
            vm.match = vm.snippet.match(/\ssrc="([\w-;:\/.,%#?+^=()&@'*]*)"/) || [];
        }

        function canReadSource () {
            return Array.isArray(vm.match) && vm.match.length > 0;
        }

        function getSource () {
            return vm.match[1];
        }
    }
})();
