(function () {
    'use strict';

    angular.module('app.layout').component('submenuDefault', {
        templateUrl: 'app/layout/submenu/submenu.template.html',
        controller: SubmenuController,
        controllerAs: 'vm',
    });

    SubmenuController.$inject = [
        'gettextCatalog',
        'ng2SessionService',
        '$filter',
        '$state',
        'MON_MODULES',
        'ng2ActiveFeatureService',
        'ng2MonEventsService',
        'ng2CoreLoginService',
        'activeFeatures',
        'Lodash',
        'ROLES_ROLE_SECTIONS',
        'RoleValidationService',
        'RolesService',
    ];
    function SubmenuController (
        gettextCatalog,
        ng2SessionService,
        $filter,
        $state,
        MON_MODULES,
        ng2ActiveFeatureService,
        ng2MonEventsService,
        ng2CoreLoginService,
        activeFeatures,
        Lodash,
        ROLES_ROLE_SECTIONS,
        RoleValidationService,
        RolesService,
    ) {
        var vm = this;
        var MINI_MENU_CLASSNAME = 'mini-menu';
        var menuOverSize = 0;
        var updateMenuAppearanceDebounced = Lodash.debounce(updateMenuAppearance, 10);

        vm.isSubentryActive = isSubentryActive;
        vm.$onInit = activate;
        vm.$onDestroy = deactivate;

        // ///////////////////////////////////////

        function activate () {
            vm.todos = 12;
            vm.links = [];
            vm.roleSections = null;
            vm.eventId = ng2MonEventsService.addListener('changed-domain', function () {
                getLinks();
            });
            if (activeFeatures.roles) {
                RolesService.roles.subscribe(function () {
                    getLinks();
                });
            }
            getLinks();

            updateMenuAppearanceDebounced();
            window.removeEventListener('resize', updateMenuAppearanceDebounced);
            window.addEventListener('resize', updateMenuAppearanceDebounced);
        }

        function deactivate () {
            window.removeEventListener('resize', updateMenuAppearanceDebounced);
            ng2MonEventsService.remove(vm.eventId);
        }

        function updateMenuAppearance () {
            var submenu = document.getElementById('submenu');
            var appMainMenu = document.getElementById('menu');
            var menuList = submenu && submenu.querySelector('ul.nav');

            if (!menuList || !appMainMenu) {
                return;
            }
            var bodyWidth = document.body.clientWidth;

            if (appMainMenu.classList.value && appMainMenu.classList.value.indexOf(MINI_MENU_CLASSNAME) > -1) {
                if (bodyWidth > menuOverSize) {
                    appMainMenu.classList.remove(MINI_MENU_CLASSNAME);
                }
            }
            if (menuList.clientWidth >= bodyWidth) {
                menuOverSize = menuList.clientWidth;
                appMainMenu.classList.add(MINI_MENU_CLASSNAME);
            }

        }

        function isSubentryActive (destination) {
            return $state.current.name.indexOf(destination) > -1;
        }

        function getLinks () {
            ng2CoreLoginService.getUser().then(function (user) {
                if (ng2SessionService.domain) {
                    vm.links = [];
                    var links = [
                        // {
                        //     notification: vm.todos > 0,
                        //     /// Sub-Menu
                        //     name: gettextCatalog.getString("To do's") + " (" + vm.todos + ")",
                        //     href: '#',
                        //     icon: 'fa-check-circle-o'
                        // },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('Dashboard'),
                            event: 'dashboard',
                            section: 'base.customer.domain.dashboard',
                            href: 'base.customer.domain.dashboard',
                            icon: $filter('monIcons')(['MENUES', 'DASHBOARD']),
                            tooltip: gettextCatalog.getString('Dashboard'),
                            module: null,
                            role: null,
                        },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('Prioritized Content'),
                            event: 'prioritized-content',
                            section: 'base.customer.domain.pages',
                            show: hasCustomer() && ng2SessionService.customer.plan_traits.prioritized_content_all,
                            href: 'base.customer.domain.pages.index',
                            icon: $filter('monIcons')(['MENUES', 'PRIORITIZED']),
                            tooltip: gettextCatalog.getString('Prioritized Content'),
                            module: MON_MODULES.content_pages,
                            role: null,
                        },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('Policies'),
                            event: 'policies',
                            show: hasCustomer() && ng2SessionService.customer.plan_traits.policies_all,
                            section: 'base.customer.domain.policy',
                            href: 'base.customer.domain.policy.dashboard',
                            icon: $filter('monIcons')(['MENUES', 'POLICIES']),
                            tooltip: gettextCatalog.getString('Content Policies'),
                            module: MON_MODULES.policies,
                            role: ROLES_ROLE_SECTIONS.policies,
                        },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('Quality Assurance'),
                            event: 'quality-assurance',
                            show:
                                hasCustomer &&
                                (ng2SessionService.customer.plan_traits.qa_spellcheck ||
                                    ng2SessionService.customer.plan_traits.qa_links ||
                                    ng2SessionService.customer.plan_traits.readability),
                            // TODO create function to validate qa show: hasCustomer() && ng2SessionService.customer.plan_traits,
                            section: 'base.customer.domain.qa',
                            href: 'base.customer.domain.qa.summary',
                            icon: $filter('monIcons')(['MENUES', 'QA']),
                            tooltip: gettextCatalog.getString('Quality Assurance'),
                            module: MON_MODULES.qa,
                            role: ROLES_ROLE_SECTIONS.qa,
                        },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('Accessibility'),
                            event: 'accessibility',
                            section: 'base.customer.domain.accessibility',
                            show: ng2SessionService.domain.features.accessibility,
                            href: 'base.customer.domain.accessibility.summary',
                            icon: $filter('monIcons')(['MENUES', 'ACCESSIBILITY']),
                            tooltip: gettextCatalog.getString('Accessibility'),
                            module: MON_MODULES.accessibility,
                            role: ROLES_ROLE_SECTIONS.accessibility,
                        },
                        {
                            notification: false,
                            name: gettextCatalog.getString('SEO'),
                            event: 'seo',
                            show: hasCustomer() && ng2SessionService.customer.plan_traits.seo_all,
                            section: 'base.customer.domain.seo',
                            href: 'base.customer.domain.seo.summary',
                            icon: $filter('monIcons')(['MENUES', 'SEO']),
                            tooltip: gettextCatalog.getString('SEO'),
                            module: MON_MODULES.seo,
                            role: ROLES_ROLE_SECTIONS.seo,
                        },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('Heartbeat'),
                            event: 'heartbeat',
                            show: hasCustomer() && ng2SessionService.customer.plan_traits.heartbeat,
                            section: 'base.customer.domain.uptimes',
                            href: 'base.customer.domain.uptimes.index',
                            icon: $filter('monIcons')(['MENUES', 'HEARTBEAT']),
                            tooltip: gettextCatalog.getString('Heartbeat'),
                            module: MON_MODULES.uptime,
                            role: ROLES_ROLE_SECTIONS.heartbeat,
                        },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('PageCorrect'),
                            event: 'pagecorrect',
                            section: 'base.customer.domain.page_fix',
                            show: ng2ActiveFeatureService.isFeatureActive('page_fix'),
                            href: 'base.customer.domain.page_fix.summary',
                            icon: $filter('monIcons')(['MENUES', 'PAGE_FIX']),
                            tooltip: gettextCatalog.getString('PageCorrect'),
                            module: null,
                            role: ROLES_ROLE_SECTIONS.pagecorrect,
                        },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('Data Privacy'),
                            event: 'data-privacy',
                            section: 'base.customer.domain.data_privacy',
                            // show: ng2ActiveFeatureService.isFeatureActive("data_privacy"),
                            href: 'base.customer.domain.data_privacy.summary',
                            icon: $filter('monIcons')(['MENUES', 'DATA_PRIVACY']),
                            tooltip: gettextCatalog.getString('Data Privacy'),
                            module: MON_MODULES.data_privacy,
                            role: ROLES_ROLE_SECTIONS.data_privacy,
                            isBeta: false,
                        },
                        {
                            name: gettextCatalog.getString('Performance'),
                            event: 'performance',
                            section: 'base.customer.domain.page_performance',
                            show: ng2ActiveFeatureService.isFeatureActive('page_performance'),
                            href: 'base.customer.domain.page_performance.pages',
                            icon: $filter('monIcons')(['MENUES', 'PAGE_PERFORMANCE']),
                            tooltip: gettextCatalog.getString('Performance'),
                            module: MON_MODULES.performance,
                            role: ROLES_ROLE_SECTIONS.page_performance,
                        },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('Statistics'),
                            event: 'statistics',
                            show: allowStatistics(),
                            section: 'base.customer.domain.statistics',
                            href: 'base.customer.domain.statistics.summary',
                            icon: $filter('monIcons')(['MENUES', 'STATISTICS']),
                            tooltip: gettextCatalog.getString('Statistics'),
                            module: MON_MODULES.statistics,
                            role: ROLES_ROLE_SECTIONS.statistics,
                        },
                        {
                            notification: false,
                            // / Sub-Menu
                            name: gettextCatalog.getString('Inventory'),
                            event: 'inventory',
                            show: hasCustomer() && ng2SessionService.customer.plan_traits.inventory_all,
                            section: 'base.customer.domain.inventory',
                            href: 'base.customer.domain.inventory.dashboard',
                            icon: $filter('monIcons')(['MENUES', 'INVENTORY']),
                            tooltip: gettextCatalog.getString('Inventory'),
                            module: MON_MODULES.inventory,
                            role: ROLES_ROLE_SECTIONS.inventory,
                        },
                    ];
                    links = filterForDomainGroups(links);
                    links = filterNewPages(links);
                    links = filterByCustomerSettings(links);
                    if (activeFeatures.roles) {
                        links = filterByRoles(links);
                    }
                    vm.links = links.filter(function (link) {
                        var hasAccess = link.module === null || $filter('hasAccess')(user, link.module);

                        // Extra check for Data Privacy,
                        // since we still need to show the link,
                        // if user has access to Consent Manager
                        if (!hasAccess && link.module === 'dataprivacy') {
                            hasAccess = $filter('hasAccess')(user, 'cookie_banner');
                        }

                        return hasAccess;
                    });
                }
            }, angular.noop);
        }

        function filterForDomainGroups (links) {
            if (ng2SessionService.domainGroup !== null) {
                var blacklist = ['base.customer.domain.uptimes'];
                return links.filter(function (data) {
                    return blacklist.indexOf(data.section) === -1;
                });
            } else {
                return links;
            }
        }

        function filterNewPages (links) {
            var validate = $filter('activeFeature')('global_policy');
            if (!validate) {
                // Domain Policy
                return links.filter(function (link) {
                    return link.section !== 'base.customer.domain.policy';
                });
            } else {
                return links;
            }
        }

        function filterByCustomerSettings (links) {
            // Only validate menus that has customerParam
            return links.filter(function (link) {
                if (link.hasOwnProperty('customerParam') && ng2SessionService.customer.settings.hasOwnProperty(link.customerParam)) {
                    return ng2SessionService.customer.settings[link.customerParam];
                } else {
                    return true;
                }
            });
        }

        function filterByRoles (links) {
            return links.filter(function (link) {
                return RoleValidationService.hasAccessToRoleSection(link.role);
            });
        }

        function allowStatistics () {
            if ($filter('monCustomerHasPlan')('demo')) {
                return true;
            }
            if (ng2SessionService.domain && ng2SessionService.domainGroup === null) {
                return ng2SessionService.domain.features.statistics;
            } else if (ng2SessionService.domainGroup !== null) {
                return (
                    ng2SessionService.domain.features.statistics &&
                    hasCustomer() &&
                    ng2SessionService.customer.plan_traits.domain_group_statistics
                );
            }
        }

        function hasCustomer () {
            return ng2SessionService.customer !== null;
        }
    }
})();
