import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboardNotifications', {
        templateUrl: 'app/pages/domain/blocks/notifications/notifications.html',
        controller: DashboardController,
        controllerAs: 'vm',
        bindings: {
            contentCount: '<',
            pageCount: '<',
            pdfCount: '<',
            documentCount: '<',
        },
    });

    DashboardController.$inject = [
        'SpellingRepo',
        'ng2QualityRepoService',
        'LinksRepo',
        '$q',
        'ng2MonEventsService',
        'ng2SeoRepoService',
        'PolicyRepo',
        '$filter',
        'ng2SessionService',
        '$state',
        'ng2StatsRepoService',
        'moment',
        'QAImportantHighlightsService',
        'MON_MODULES',
        'ng2ActiveFeatureService',
        'ng2MonUIRouterTransitionService',
    ];
    /* @ngInject */
    function DashboardController (
        SpellingRepo,
        ng2QualityRepoService,
        LinksRepo,
        $q,
        ng2MonEventsService,
        ng2SeoRepoService,
        PolicyRepo,
        $filter,
        ng2SessionService,
        $state,
        ng2StatsRepoService,
        moment,
        QAImportantHighlightsService,
        MON_MODULES,
        ng2ActiveFeatureService,
        ng2MonUIRouterTransitionService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.openDialog = openDialog;
        vm.openSEODialog = openSEODialog;
        vm.openPolicyDialog = openPolicyDialog;
        vm.goToStatistics = goToStatistics;
        vm.goTo = goTo;
        vm.showBrokenLink = QAImportantHighlightsService.showBrokenLink;
        vm.showBrokenImage = QAImportantHighlightsService.showBrokenImage;
        vm.showSpelling = ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck');

        function activate () {
            var user = ng2SessionService.me;
            vm.showStatistics = false;
            if (ng2SessionService.domain !== null) {
                vm.domainId = ng2SessionService.domain.id;
                vm.showStatistics =
                    $filter('hasAccess')(user, MON_MODULES.statistics) &&
                    ng2SessionService.domain.features.statistics &&
                    ng2SessionService.domainGroup === null;
                vm.showQA = $filter('hasAccess')(user, MON_MODULES.qa);
                vm.showSEO = $filter('hasAccess')(user, MON_MODULES.seo) && ng2ActiveFeatureService.isFeatureActivePure('seo_all');
                vm.showPolicy = $filter('hasAccess')(user, MON_MODULES.policies) &&
                    $filter('activeFeature')('global_policy') &&
                    ng2ActiveFeatureService.isFeatureActivePure('policies_all');

                setupPromises();
                vm.brokenLinkText = QAImportantHighlightsService.getBrokenLinkText();
                vm.brokenImageText = QAImportantHighlightsService.getBrokenImageText();
                vm.brokenLinkType = QAImportantHighlightsService.getLinkSearchType();
            }
        }

        function setupPromises () {
            vm.loading = true;
            var promises = [];
            if (vm.showQA) {
                promises.push(
                    getLinks('not_image', 'dead').then(function (links) {
                        vm.brokenLink = links.length > 0 ? links[0] : null;
                    }, angular.noop),
                );

                promises.push(
                    getLinks('image', 'dead').then(function (images) {
                        vm.brokenImage = images.length > 0 ? images[0] : null;
                    }, angular.noop),
                );

                promises.push(
                    getQASummary().then(function (summary) {
                        vm.data = summary;
                    }, angular.noop),
                );

                promises.push(
                    getSpellingErrors().then(function (spellingErrors) {
                        vm.spellingErrors = spellingErrors;
                    }, angular.noop),
                );
            }

            if (vm.showSEO) {
                promises.push(
                    getSEOIssues().then(function (pages) {
                        vm.seoError = pages.length > 0 ? pages[0] : null;
                    }, angular.noop),
                );
            }

            if (vm.showStatistics) {
                promises.push(
                    getPagesWithoutVisit().then(function (pages) {
                        vm.pagesWithoutVisit = pages.total;
                    }, angular.noop),
                );
            }

            if (vm.showPolicy) {
                promises.push(
                    getPolicies().then(function (policies) {
                        if (policies.length > 0 && policies[0].matches_count > 0) {
                            vm.policyError = policies[0];
                        }
                    }, angular.noop),
                );
            }

            $q.all(promises).finally(function () {
                vm.loading = false;
            });
        }

        function getQASummary () {
            return ng2QualityRepoService.getSummary({
                domainId: vm.domainId,
            });
        }

        function getSpellingErrors () {
            return SpellingRepo.getAllErrors({
                domainId: vm.domainId,
                page: 1,
                page_size: 1,
                sort_by: 'html_pages_count',
                sort_dir: 'desc',
                classification: 'confirmed',
                page_fixed: false,
            });
        }

        function getLinks (type, status, sortBy) {
            return LinksRepo.getAll({
                page: 1,
                page_size: 1,
                status: status,
                type: type,
                sort_by: sortBy || 'dead_on_pages_count',
                sort_dir: 'desc',
                page_fixed: false,
            });
        }

        function getSEOIssues () {
            return ng2SeoRepoService.getAll({
                page: 1,
                page_size: 1,
                sort_by: 'pages_count',
                sort_dir: 'desc',
            });
        }

        function getPolicies () {
            return PolicyRepo.domainGetAll({
                domainId: vm.domainId,
                page: 1,
                page_size: 1,
                sort_by: 'matches_count',
                sort_dir: 'desc',
            });
        }

        function getPagesWithoutVisit () {
            return ng2StatsRepoService.getPagesWithoutVisits({
                limit: 2,
                page: 1,
                domainId: vm.domainId,
                from: moment().subtract(181, 'days')
                    .format('YYYY-MM-DD'),
                to: moment().subtract(1, 'days')
                    .endOf('day')
                    .format('YYYY-MM-DD'),
            });
        }

        function openSEODialog () {
            var dialog;

            switch (vm.seoError.name) {
                case 'seo_duplicate_title':
                    dialog = 'seoDialogPagesWithDuplicateTitle';
                    break;
                default:
                    dialog = 'pagesFromSeoIssues';
            }

            openDialog(dialog, 'lg', { issue: vm.seoError, pageCount: vm.pageCount });
        }

        function openPolicyDialog () {
            if (vm.policyError.subject === 'link') {
                openDialog('pagesPolicyDialogLinks', 'page-details', { policy: vm.policyError });
            } else {
                openDialog('pagesPolicyDialogPages', 'page-details', {
                    policy: vm.policyError,
                    pageCount: vm.pageCount,
                    contentCount: vm.contentCount,
                    pdfCount: vm.pdfCount,
                    documentCount: vm.documentCount,
                });
            }
        }

        function openDialog (target, size, attrs) {
            if (size.constructor === Object) {
                attrs = size;
                size = 'page-details';
            }

            var params = {
                body: target,
                size: size,
                data: attrs,
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function goToStatistics () {
            goTo('base.customer.domain.statistics.pages.withoutVisits');
        }

        function goTo (state) {
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go(state),
            );
        }
    }
})();
