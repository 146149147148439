(function () {
    'use strict';

    /**
     * @memberof modules.trap-focus
     * @ngdoc service
     * @name translation
     * @description Service to set the Global Locale settings.
     */
    angular.module('modules.trap-focus').service('trapFocus', trapFocus);

    trapFocus.$inject = ['$focusTrap'];
    /* ngInject */
    function trapFocus ($focusTrap) {
        var list = [];
        return {
            create: create,
            unPauseLast: unPauseLast,
            pauseLast: pauseLast,
            remove: remove,
        };

        // /////////////

        function create (elements, userOptions) {
            var focus = $focusTrap.createFocusTrap(elements, userOptions);
            focus.activate();
            focus.id = Date.now();
            list.push(focus);
            return focus;
        }

        function unPauseLast () {
            var focus = getLast();
            if (focus) {
                focus.unpause();
            }
        }

        function pauseLast () {
            var focus = getLast();
            if (focus) {
                focus.pause();
            }
        }

        function remove (focus) {
            if (focus && focus.id) {
                var entry = findFocus(focus.id);
                if (entry) {
                    entry.deactivate();
                    list.splice(list.indexOf(focus), 1);
                }
            }
        }

        // //// Protected

        function getLast () {
            return list[list.length - 1];
        }

        function findFocus (id) {
            return list.find(function (focus) {
                return focus.id === id;
            });
        }
    }
})();
