import { Injectable } from '@angular/core';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { cloneDeep, sortBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { DomainRepoService } from '../api/domain-repo/domain-repo.service';
import { CollectionInterface } from '@monsido/angular-shared-components/dist/angular-shared-components/lib/interfaces/collection.interface';
import { EssentialDomainDataModel } from './essential-domain-data.model';

type DomainsRequestParamsType = {page: number; page_size: number;};

type StartFetchingOptionsType = {
    params: DomainsRequestParamsType;
    domainCollection: CollectionInterface<Domain>;
    isRecursive: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class TotalDomainsService {

    domainsEssential: BehaviorSubject<EssentialDomainDataModel[] | null> = new BehaviorSubject(null);
    totalDomains: BehaviorSubject<number | null> = new BehaviorSubject(null);
    fetchingDomains: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private currentFetchId: number = 0;
    private mustStop: Set<number> = new Set();
    private delayedQueue: Array<() => unknown> = [];

    constructor (
        private domainRepoService: DomainRepoService,
    ) {}

    requestAllDomains (options: StartFetchingOptionsType): Promise<void> {
        if (this.currentFetchId && this.fetchingDomains.getValue()) {
            this.mustStop.add(this.currentFetchId);
        }
        this.currentFetchId = Date.now() + Math.random();
        return this.startFetching(options, this.currentFetchId);
    }

    private startFetching (options: StartFetchingOptionsType, fetchId: number): Promise<void> {
        const { params } = options;
        let domainCollection = options.domainCollection || [];
        if (this.mustStop.has(fetchId)) {
            this.mustStop.delete(fetchId);
            return Promise.resolve();
        }
        const isRecursive = options.isRecursive || false;

        if (!params.page) {
            params.page = 1;
        } else {
            params.page++;
        }

        this.fetchingDomains.next(true);

        return this.domainRepoService.getAll(params).then((domains) => {
            if (!isRecursive) {
                this.totalDomains.next(domains.total == null ? null : domains.total);
            }
            domainCollection = domainCollection.concat(domains) as CollectionInterface<Domain>;
            this.publishDomains(domainCollection);

            if (domains.length < params.page_size) {
                this.fetchingDomains.next(false);
                this.doWhenReady();
                return Promise.resolve();
            }
            return this.startFetching({
                params,
                domainCollection,
                isRecursive: true,
            }, fetchId);
        });
    }

    resetDomains (): void {
        this.delayedQueue = [];
        this.mustStop.add(this.currentFetchId);
        try {
            this.domainsEssential.next([]);
            this.totalDomains.next(0);
            this.fetchingDomains.next(false);
        } catch (e) {
            // Old unit tests may fail otherwise, due to complicated dependency injection
            // Please try to remove this block of code, when the upgrade is completed
            this.domainsEssential = new BehaviorSubject([]);
            this.totalDomains = new BehaviorSubject(null);
            this.fetchingDomains = new BehaviorSubject(false);

        }
    }

    // Update/create domain
    updateDomain (domain: EssentialDomainDataModel): void {
        if (this.fetchingDomains.getValue()) {
            this.delayedQueue.push(() => {
                this.updateDomain(domain);
            });
        }
        const domainId = domain.id;
        const currentDomains = cloneDeep(this.domainsEssential.getValue()) || [];
        const domainData = cloneDeep(domain);
        let newDomain = true;

        for (let i = 0; i < currentDomains.length; i += 1) {
            if (currentDomains[i].id === domainId) {
                currentDomains[i] = domainData;
                newDomain = false;
                break;
            }
        }

        if (newDomain) {
            currentDomains.push(domainData);
        }
        this.domainsEssential.next(currentDomains);
    }

    deleteDomain (domainId: number): void {
        if (this.fetchingDomains.getValue()) {
            this.delayedQueue.push(() => {
                this.deleteDomain(domainId);
            });
            return;
        }
        const currentDomains = cloneDeep(this.domainsEssential.getValue()) || [];

        for (let i = 0; i < currentDomains.length; i += 1) {
            if (currentDomains[i].id === domainId) {
                currentDomains.splice(i, 1);
                break;
            }
        }
        this.domainsEssential.next(currentDomains);
    }

    private doWhenReady (): void {
        while (this.delayedQueue.length) {
            const method = this.delayedQueue.shift();
            if (method) {
                method();
            }
        }
    }

    private publishDomains (domains: Domain[]): void {
        const currentDomains = domains.sort(function (a, b) {
            return a.favorite && !b.favorite ? -1 : !a.favorite && b.favorite ? 1 : 0;
        });
        currentDomains.forEach(function (domain) {
            domain.domain_groups = sortBy(domain.domain_groups, ['title']);
        });

        this.domainsEssential.next(currentDomains.map(d => new EssentialDomainDataModel(d)));
    }

}
