(function () {
    'use strict';

    angular.module('app.layout').component('menuSearchInput', {
        templateUrl: 'app/layout/menu/search-input/search-input.html',
        controller: MenuSearchInputController,
        controllerAs: 'vm',
    });

    MenuSearchInputController.$inject = [
        'moment',
        'gettextCatalog',
        'ng2SessionService',
        'hotkeys',
        '$scope',
        'focus',
        'ng2MonEventsService',
        'rx',
        'ng2TotalDomainsService',
    ];
    function MenuSearchInputController (moment, gettextCatalog, ng2SessionService, hotkeys, $scope, focus, ng2MonEventsService, rx, ng2TotalDomainsService) {
        var vm = this;
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.setPlaceholder = setPlaceholder;
        vm.getLastScan = getLastScan;
        vm.startSwitchDomain = startSwitchDomain;
        vm.showProjectSearch = showProjectSearch;
        vm.onSearchChange = onSearchChange;
        vm.hideDomains = false;
        vm.search = '';
        vm.searchText = new rx.BehaviorSubject('');
        const MAX_VISIBLE_DOMAINS = 200;
        let changeDomainListener = null;
        let loading = true;
        let totalDomains$;

        function activate () {
            vm.search = '';
            vm.projectSearch = false;
            vm.mouseOverSearch = false;
            vm.switchDomain = false;
            vm.domain = ng2SessionService.domain;
            vm.domainGroup = ng2SessionService.domainGroup;
            setPlaceholder();
            hotkeysInit();
            listeners();
            totalDomains$ = ng2TotalDomainsService.totalDomains.subscribe((totalDomains) => {
                if (totalDomains) {
                    vm.hideDomains = totalDomains > MAX_VISIBLE_DOMAINS;
                    loading = false;
                    setPlaceholder();
                }
            });
        }

        function onDestroy () {
            totalDomains$.unsubscribe();
            ng2MonEventsService.remove(changeDomainListener);
        }

        function hotkeysInit () {
            hotkeys
                .bindTo($scope)
                .add({
                    combo: 'ctrl+d',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Show/Hide the Domain Picker'),
                    callback: function (event) {
                        if (ng2SessionService.customer) {
                            event.preventDefault();
                            showProjectSearch(!vm.projectSearch);
                        }
                        if (vm.projectSearch) {
                            focus('project-search-input-field');
                        }
                    },
                })
                .add({
                    combo: 'd',
                    description: gettextCatalog.getString('Show the Domain Picker'),
                    callback: function (event, hotkey) {
                        if (ng2SessionService.customer) {
                            event.preventDefault();
                            showProjectSearch(!vm.projectSearch);
                        }
                        if (vm.projectSearch) {
                            focus('project-search-input-field');
                        }
                    },
                })
                .add({
                    combo: 'ctrl+shift+d',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Clear search in the domain picker.'),
                    callback: function () {
                        vm.search = '';
                        vm.searchText.onNext(vm.search);
                    },
                })
                .add({
                    combo: 'escape',
                    allowIn: ['INPUT'],
                    description: gettextCatalog.getString('Close project search and domain picker'),
                    callback: function () {
                        vm.search = '';
                        vm.searchText.onNext(vm.search);
                        showProjectSearch(false);
                    },
                });
        }

        function onSearchChange () {
            vm.showProjectSearch(true);
            vm.searchText.onNext(vm.search);
        }

        function listeners () {
            changeDomainListener = ng2MonEventsService.addListener('changed-domain', function () {
                vm.domain = ng2SessionService.domain;
                vm.domainGroup = ng2SessionService.domainGroup;
                vm.switchDomain = false;
                if (ng2SessionService.domain) {
                    vm.search = '';
                    vm.searchText.onNext(vm.search);
                    vm.focusedDomainTitle = ng2SessionService.domain.title;
                }
                setPlaceholder();
            });
        }

        function setPlaceholder () {
            if (vm.focusedDomainTitle) {
                if (ng2SessionService.domain && ng2SessionService.domainGroup) {
                    vm.placeholder = ng2SessionService.domain.title + ' > ' + ng2SessionService.domainGroup.title;
                } else {
                    vm.placeholder = vm.focusedDomainTitle;
                }
                vm.focusedDomainTitle = '';
            } else if (vm.domain) {
                vm.placeholder = vm.domain.title;
                if (vm.domainGroup !== null) {
                    vm.placeholder += ' > ' + vm.domainGroup.title;
                }
            } else if (loading) {
                vm.placeholder = gettextCatalog.getString('Loading...');
            } else if (vm.hideDomains) {
                vm.placeholder = gettextCatalog.getString('Search for domain');
            } else {
                vm.placeholder = gettextCatalog.getString('Select domain');
            }
            return vm.placeholder;
        }

        function getLastScan () {
            if (vm.domain) {
                if (vm.domain.crawl_history) {
                    var tmpStart = moment(vm.domain.crawl_history.post_processing_done_at);
                    var tmpEnd = moment();

                    return gettextCatalog.getString('Last scan: {{duration}} ago', {
                        duration: moment.duration(tmpEnd - tmpStart).humanize(),
                    });
                } else {
                    return gettextCatalog.getString('Last scan never');
                }
            }

            return '';
        }

        function startSwitchDomain () {
            vm.switchDomain = true;
            vm.showProjectSearch(false);
        }

        function showProjectSearch (show) {
            vm.projectSearch = show;
        }
    }
})();
