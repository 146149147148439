(function () {
    'use strict';

    angular.module('services.api').factory('PagePerformanceHistoryRepo', PagePerformanceHistoryRepo);

    PagePerformanceHistoryRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'PagePerformanceHistoryModel'];

    /* @ngInject */
    function PagePerformanceHistoryRepo (ng2ApiService, ng2SessionService, PagePerformanceHistoryModel) {
        return {
            get: get,
        };

        // /////////////

        function get (params, config) {
            config = config || {};
            params = params || {};
            return ng2ApiService.get('performance/history', params, config).then(toModels);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new PagePerformanceHistoryModel(model);
        }
    }
})();
